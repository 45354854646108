// project import
import Routes from "routes";
import ThemeCustomization from "themes";

import Locales from "components/Locales";
import RTLLayout from "components/RTLLayout";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import ModalProvider from "mui-modal-provider";

// auth-provider
import { Auth0Provider as AuthProvider } from "contexts/Auth0Context";
import { NotificationProvider } from "contexts/NotificationContext";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <ModalProvider>
              <AuthProvider>
                <NotificationProvider>
                  <Notistack>
                    <Routes />
                    <Snackbar />
                  </Notistack>
                </NotificationProvider>
              </AuthProvider>
            </ModalProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
