import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material"
import { FormFieldProps } from ".";

export type RadioControlProps = FormFieldProps & {
    value: string | number;
    onChange: (value: string) => void;
    options: { value: string, label: string }[]
}

const RadioControl = (props: RadioControlProps) => {
    const { label, required, id, name, value, onChange, error, helperText, disabled, options } = props
    return (
        <>
            <FormControl>
                {
                    label &&
                    <FormLabel
                        component="legend"
                        required={required}
                    >
                        {label}
                    </FormLabel>
                }
                <RadioGroup
                    row
                    aria-label={id}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    id={id}
                >
                    {
                        options.map((option) => <FormControlLabel
                            key={option.value} value={option.value}
                            control={<Radio disabled={disabled} />}
                            label={option.label}
                        />)
                    }
                </RadioGroup>
            </FormControl>
            {error && (
                <FormHelperText
                    error={error}
                >
                    {helperText}
                </FormHelperText>
            )}
        </>
    )
}

export default RadioControl