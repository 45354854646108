import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthProps } from '../../types/auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  token: null,
  user: null,
};


// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to set the user when authenticated
    setUser: (state, action: PayloadAction<AuthProps>) => {
      state.user = action.payload.user
    },
    setLogin: (state, action: PayloadAction<AuthProps>) => {
      const { isLoggedIn = false, isInitialized = false, user, token } = action.payload
      state.isLoggedIn = isLoggedIn
      state.isInitialized = isInitialized
      state.token = token
      state.user = {...state.user, ...user}
    },
    setAuthRole: (state, action: PayloadAction<AuthProps>) => {
      const { user } = action.payload
      state.isInitialized = true
      state.user = {...state.user, ...user}
    },
    // Action to log out the user
    setlogout: (state) => {
      state.isLoggedIn = false
      state.isInitialized = true
      state.user = null
      state.token = null
    },
  },
});

// Export the reducer and actions
export const { setUser, setlogout, setLogin, setAuthRole} = authSlice.actions;
export default authSlice.reducer;