import { InputLabel, Autocomplete, TextField, CircularProgress } from "@mui/material";
import { FormFieldProps } from ".";
import { FocusEvent } from 'react';
import React from "react";

type OptionType = { value: string; label: string; }

export type MutiSelectControlProps = FormFieldProps & {
    value: OptionType[];
    onChange: (newValue: OptionType[]) => void;
    options: OptionType[];
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    loading?: boolean;
}

const MutiSelectControl = (props: MutiSelectControlProps) => {
    const { label, required, id, value, onChange, error, helperText, disabled, options,
        onBlur, loading } = props

    return (
        <>
            <InputLabel sx={{ pb: 1 }} required={required}>
                {label}
            </InputLabel>
            <Autocomplete disabled={disabled} readOnly={disabled}
                id={id}
                options={options}
                disableCloseOnSelect
                onBlur={onBlur}
                getOptionLabel={(option) => option.label}
                multiple={true}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={error}
                        helperText={helperText}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            sx={{ color: "#391474" }}
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </>
    )
}

export default MutiSelectControl