import { createApi } from "@reduxjs/toolkit/query/react";
import { current } from "@reduxjs/toolkit";
import {
  CreateAdminUser,
  CreateAdminUserData,
  SearchParam,
  UpdateRoleData,
  GetAllAdminUserInfo,
  AdminUserInfoDetails,
  AdminList,
  GetAdminInfo,
  UpdateAdminStatus,
  DeleteAdminUserQuery,
  DeleteAdminUserResponse,
} from "../../types/admin-user";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const ADMIN_API_REDUCER_KEY = "adminApi";

export const adminApi = createApi({
  reducerPath: ADMIN_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["AdminUsers"],
  endpoints: (builder) => ({
    getAdminUserList: builder.query<AdminList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.ADMIN_LISTING,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["AdminUsers"],
    }),
    getAllActiveAdminList: builder.query<AdminList, {}>({
      query: () => {
        return {
          url: ApiConstant.ACTIVE_ADMIN_LISTING,
          method: "GET",
        };
      },
    }),

    // ViewAdminData
    getAdminById: builder.query<GetAdminInfo, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.ADMIN_BY_ID}/${id}`,
          method: "GET",
        };
      },
    }),
    createAdmin: builder.mutation<
      CreateAdminUserData,
      Partial<CreateAdminUser>
    >({
      query(body) {
        return {
          url: ApiConstant.CREATE_ADMIN,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["AdminUsers"],
    }),
    editAdmin: builder.mutation<
      UpdateRoleData,
      Partial<AdminUserInfoDetails> & Pick<GetAllAdminUserInfo, "ID">
    >({
      query(body) {
        return {
          url: `${ApiConstant.CREATE_ADMIN}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["AdminUsers"],
    }),
    changeAdminStatus: builder.mutation<UpdateAdminStatus, string>({
      query: (body) => {
        return {
          url: ApiConstant.CHANGE_ADMIN_STATUS,
          method: "POST",
          body,
        };
      },
      // eslint-disable-next-line no-empty-pattern
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            adminApi.util.updateQueryData(
              "getAdminUserList",
              { pageNo: 1, size: 30, searchText: "", filter: [] },
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );

                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.IsActive = updatedInfo.data.IsActive;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    deleteAdminUser: builder.mutation<
      DeleteAdminUserResponse,
      DeleteAdminUserQuery
    >({
      query: (args: DeleteAdminUserQuery) => {
        const { ID } = args;
        return {
          url: ApiConstant.DELETE_ADMIN_USER(ID),
          method: "DELETE",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            adminApi.util.updateQueryData("getAdminUserList", rest, (draft) => {
              console.log("UUData", draft.data);
              const objIndex = draft.data.findIndex(
                (item, index) => item.ID === ID
              );
              if (objIndex !== -1) {
                draft.data.splice(objIndex, 1);
              }
              console.log("Draft Info", current(draft.data));
            })
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useGetAdminUserListQuery,
  useGetAdminByIdQuery,
  useCreateAdminMutation,
  useEditAdminMutation,
  useChangeAdminStatusMutation,
  useDeleteAdminUserMutation,
  useGetAllActiveAdminListQuery,
} = adminApi;
