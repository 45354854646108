// import { useMemo } from "react";

import { Theme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

// project import
import Search from "./Search";
import Profile from "./Profile";
// import Localization from "./Localization";
import Notification from "./Notification";
// import Customization from "./Customization";
import MobileSection from "./MobileSection";

import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";

import { MenuOrientation } from "types/config";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  // const localization = useMemo(() => <Localization />, [i18n]);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      {!downLG && <Search />}
      {/* {!downLG && localization} */}
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />}

      <Notification />
      {/* <Customization /> */}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
