import { FocusEvent } from 'react';
import { InputLabel, Autocomplete, TextField, Stack, Box } from '@mui/material';
import { FormFieldProps } from 'components/formField';
export interface CountryType {
    code: string;
    label: string;
    currency: string;
    prefix: string;
}

export interface CountrySelectProps extends FormFieldProps {
    value: string;
    onChange: (value: string | null) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const countries = [
    { code: "GB-WLS", label: "Wales", currency: "Dollar", prefix: "$" },
    { code: "GB-ENG", label: "England", currency: "Pound", prefix: "£" },
    { code: "GB-SCT", label: "Scotland", currency: "Rupee", prefix: "₹" },
    { code: "GB-NIR", label: "Northern Ireland", currency: "Yun", prefix: "¥" },
];

const CountrySelect = (props: CountrySelectProps) => {
    const { label, required, id, name, onBlur, value, error, helperText, disabled, onChange } = props

    return (
        <Stack spacing={1}>
            {
                label &&
                <InputLabel required={required}>{label}</InputLabel>
            }
            <Autocomplete disabled={disabled} readOnly={disabled}
                onBlur={onBlur}
                id={id}
                fullWidth
                options={countries}
                value={countries.find(
                    (option: CountryType) =>
                        option.label === value
                )}
                onChange={(event, value) => onChange(value?.label ?? "")}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                    >
                        {option.code && (
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt="flag"
                            />
                        )}
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => {
                    const selected = countries.find(
                        (option: CountryType) =>
                            option.code === value
                    );
                    return (
                        <TextField placeholder='Country'
                            {...params}
                            name={name}
                            error={error}
                            helperText={helperText}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selected && selected.code !== "" && (
                                            <img
                                                style={{ marginRight: 6 }}
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${selected.code.toLowerCase()}.png`}
                                                alt="flag"
                                            />
                                        )}
                                    </>
                                ),
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete
                            }}
                        />
                    );
                }}
            />
        </Stack>
    )
}

export default CountrySelect