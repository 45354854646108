import { createApi } from "@reduxjs/toolkit/query/react";
import { PensionFundList, SearchParam, CreatePensionFundResponse, CreatePensionFund, UpdatePenionFund, PensionFundByIdResponse, CommentInfo, AddComment, PensionFundStatus, PensionFundStatusResponse, ActiveInvestmentOptionsList, DownloadCSVResponse } from '../../types/pensionFunds'
import { ApiConstant } from "../../constants/ApiConstants";
import { current } from "@reduxjs/toolkit";
import baseQuery from "./baseQuery";

export const PENSION_FUND_API_REDUCER_KEY = "pensionFundsApi";

export const pensionFundApi = createApi({
  reducerPath: PENSION_FUND_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["PensionFunds", "ActiveInvestmentOption"],
  endpoints: (builder) => ({
    getPensionFundList: builder.query<PensionFundList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.PENSION_FUNDS,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["PensionFunds"],
    }),
    createPensionFund: builder.mutation<CreatePensionFundResponse, Partial<CreatePensionFund>>({
      query(body) {
        return {
          url: ApiConstant.CREATE_PENSION_FUND,
          method: "POST",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["PensionFunds"]
    }),
    updatePensionFundOption: builder.mutation<CreatePensionFundResponse, UpdatePenionFund>({
      query({ ID, ...body }) {
        return {
          url: ApiConstant.UPDATE_PENSION_FUND(ID),
          method: "PATCH",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["PensionFunds"]
    }),
    getPensionFundById: builder.query<PensionFundByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PENSION_FUND_BY_ID(id)}`,
          method: "GET",
        };
      }
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.ADD_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    getActiveInvestmentOptionList: builder.query<ActiveInvestmentOptionsList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_INVESTMENT_OPTIONS,
          method: "GET",
        };
      },
      providesTags: ["ActiveInvestmentOption"],
    }),
    changePensionStatus: builder.mutation<PensionFundStatusResponse, PensionFundStatus>({
      query: (body: PensionFundStatus) => {
        const { ID, Enabled } = body;
        return {
          url: ApiConstant.CHANGE_PENSION_FUND_STATUS,
          method: "POST",
          body: { ID, Enabled }
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, Enabled, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            pensionFundApi.util.updateQueryData(
              "getPensionFundList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.Enabled = updatedInfo.data.Enabled;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      }
    }),
  }),
});

export const { useGetPensionFundListQuery, useCreatePensionFundMutation, useUpdatePensionFundOptionMutation, useGetPensionFundByIdQuery, useAddCommentMutation, useChangePensionStatusMutation, useGetActiveInvestmentOptionListQuery, useLazyDownloadCSVQuery } = pensionFundApi;
