import * as Sentry from '@sentry/react';
import { store } from 'store';
import type {
    BaseQueryApi,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'

function captureSentryError(error: FetchBaseQueryError, query?: string | FetchArgs, api?: BaseQueryApi) {
    const { user, token } = store.getState().auth
    const customizedTags: any = {
        token,
        ...user
    };

    const extraDetails = {
        payload: query,
        api,
        ...customizedTags,
    };

    for (const key in customizedTags) {
        const value = customizedTags[key];
        Sentry.setTag(`${key}`, value);
    }

    const errorDetails = {
        status: error.status,
        data: typeof error.data === 'object' ? JSON.stringify(error.data) : error.data,
    };

    Sentry.captureException(new Error('API Error'), {
        extra: {
            ...extraDetails,
            error: errorDetails,
        },
    });
}

export default captureSentryError