import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  CreateJourney, DeleteJourneyQuery, JourneyDetailsResponse, JourneyListResponse,
  JourneyStatus,
  JourneyStatusResponse,
  SearchParam,
  ThemeJourneySequence,
  ThemeSequenceResponse,
  UpdateJourney
} from "types/journey";
import { FileUpload, UploadFile, RemoveFile, RemoveFileData } from "types/employer";
import isEmpty from "lodash/isEmpty"

export const SCHEME_API_REDUCER_KEY = "journeyApi";

export const journeyApi = createApi({
  reducerPath: SCHEME_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Journey"],
  endpoints: (builder) => ({
    getJourneyList: builder.query<JourneyListResponse, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        return {
          url: ApiConstant.GET_ALL_JOURNEY_LIST,
          method: "POST",
          body: args,
        };
      },
      providesTags: ["Journey"],
    }),
    getActiveJourneyList: builder.query<JourneyListResponse, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_JOURNEY_LIST,
          method: "GET",
        };
      },
      providesTags: ["Journey"],
    }),
    fileUploader: builder.mutation<FileUpload, UploadFile>({
      query(body) {
        return {
          url: ApiConstant.JOURNEY_FILE_UPLOAD,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response.data.error
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0]
        } else {
          return response.data.message
        }
      }
    }),
    iconUploader: builder.mutation<FileUpload, UploadFile>({
      query(body) {
        return {
          url: ApiConstant.JOURNEY_ICON_UPLOAD,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response.data.error
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0]
        } else {
          return response.data.message
        }
      }
    }),
    removeFile: builder.mutation<RemoveFileData, RemoveFile>({
      query(body) {
        return {
          url: ApiConstant.JOURNEY_FILE_REMOVE,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => {
        const error = response.data.error
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0]
        } else {
          return response.data.message
        }
      },
    }),
    createJourney: builder.mutation<JourneyDetailsResponse, Partial<CreateJourney>>(
      {
        query(body) {
          return {
            url: ApiConstant.CREATE_JOURNEY_DETAILS,
            method: "POST",
            body,
          };
        },
        transformErrorResponse: (response: {
          data: { error: any; message: string };
          status: number;
        }) => {
          const error = response.data.error
          if (Array.isArray(error) && !isEmpty(error)) {
            return error[0]
          } else {
            return response.data.message
          }
        },
        invalidatesTags: ["Journey"],
      }
    ),
    updateJourney: builder.mutation<JourneyDetailsResponse, Partial<UpdateJourney>>({
      query(body) {
        return {
          url: ApiConstant.UPDATE_JOURNEY_DETAILS,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response.data.error
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0]
        } else {
          return response.data.message
        }
      },
      invalidatesTags: ["Journey"],
    }),
    getJourneyById: builder.query<JourneyDetailsResponse, string>({
      query: (ID: string) => {
        return {
          url: `${ApiConstant.GET_JOURNEY_DETAILS}`,
          method: "POST",
          body: { ID }
        };
      },
    }),
    changeJourneyStatus: builder.mutation<JourneyStatusResponse, JourneyStatus>({
      query: (body: JourneyStatus) => {
        const { ID, ThemeStatusName } = body;
        return {
          url: ApiConstant.CHANGE_JOURNEY_STATUS,
          method: "PATCH",
          body: { ID, ThemeStatusName },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, ThemeStatusName, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          const patchedResult = dispatch(
            journeyApi.util.updateQueryData(
              "getJourneyList",
              rest,
              (draft) => {
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );

                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.IsActive = updatedInfo.data.IsActive;
                  info.ThemeStatusName = updatedInfo.data.ThemeStatusName
                  draft.data[objIndex] = info;
                }
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    applyJourneySequence: builder.mutation<
      ThemeSequenceResponse,
      ThemeJourneySequence
    >({
      query: (args) => {
        const {Themes} = args
        return {
          url: ApiConstant.CHANGE_JOURNEY_SEQUENCE,
          method: "PATCH",
          body: { Themes }
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { Themes, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          dispatch(
            journeyApi.util.updateQueryData("getJourneyList", rest, (draft) => {
              draft.data = updatedInfo.data
            })
          );
        } catch (error) {
        }
      },
    }),
    deleteJourney: builder.mutation<
      JourneyStatusResponse,
      DeleteJourneyQuery
    >({
      query: (args: DeleteJourneyQuery) => {
        const { ID } = args;
        return {
          url: ApiConstant.DELETE_JOURNEY_RECORDS,
          method: "DELETE",
          body: { ID }
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            journeyApi.util.updateQueryData("getJourneyList", rest, (draft) => {
              console.log("UUData", draft.data);
              const objIndex = draft.data.findIndex(
                (item, index) => item.ID === ID
              );
              if (objIndex !== -1) {
                draft.data.splice(objIndex, 1);
              }
            })
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
  }),
});

export const {
  useLazyGetJourneyListQuery,
  useGetActiveJourneyListQuery,
  useCreateJourneyMutation,
  useUpdateJourneyMutation,
  useGetJourneyByIdQuery,
  useChangeJourneyStatusMutation,
  useDeleteJourneyMutation,
  useApplyJourneySequenceMutation,
  useRemoveFileMutation,
  useFileUploaderMutation,
  useIconUploaderMutation
} = journeyApi;
