import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";

type NotificationDialogProps = DialogProps & {
  title: string;
  body: ReactNode;
  icon: ReactNode;
  onTapYes: () => void;
  onTapNo: () => void;
  errorDialog?: Boolean;
  downloadLink: string | null
};

const NotificationDialog = (props: NotificationDialogProps) => {
  const { title, body, icon, onTapYes, onTapNo, errorDialog, downloadLink = null, ...restProps } =
    props;
  return (
    <Dialog {...restProps} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent
        sx={{
          mt: 2,
          my: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar
          color="error"
          sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
        >
          <DeleteFilled />
        </Avatar> */}
        {icon}
        <DialogContentText sx={{ mt: 2 }}>{body}</DialogContentText>
      </DialogContent>
      {errorDialog ? (
        <DialogActions sx={{ p: "20px" }}>
          <Button
            onClick={onTapNo}
            color="primary"
            variant="outlined"
            fullWidth
            sx={{ mr: 1 }}
          >
            {`Cancel`}
          </Button>
          <Button
            onClick={onTapYes}
            color="primary"
            variant="contained"
            fullWidth
          >
            {`Download error file`}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions sx={{ p: "20px" }}>
          <Button
            onClick={onTapNo}
            color="primary"
            variant="outlined"
            fullWidth
            sx={{ mr: 1 }}
          >
            {
              downloadLink ? "Cancel" : "Close"
            }
          </Button>
          {
            downloadLink &&
            <Button
              onClick={onTapYes}
              color="primary"
              variant="contained"
              fullWidth
            >
              Download
            </Button>
          }
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NotificationDialog;
