import { createApi } from "@reduxjs/toolkit/query/react";
import { SearchParam, InvestmentOptionList, InvestmentOptionInfo, InvestmentOptionResponse, InvestmentOption, CommentInfo, AddComment, InvestmentOptionInfoByIdResponse, ActiveProviderList, InvestmentOptionStatusResponse, InvestmentOptionStatus, DownloadCSVResponse } from '../../types/investmentOptions'
import { ApiConstant } from "../../constants/ApiConstants";
import { current } from "@reduxjs/toolkit";
import baseQuery from "./baseQuery";

export const INVESTMENT_OPTIONS_API_REDUCER_KEY = "investmentOptionsApi";

export const investmentOptionApi = createApi({
  reducerPath: INVESTMENT_OPTIONS_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["InvestmentOptions", "ActiveProvider"],
  endpoints: (builder) => ({
    getInvestmentOptionList: builder.query<InvestmentOptionList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.INVESTMENT_OPTIONS,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["InvestmentOptions"],
    }),
    createInvestmentOption: builder.mutation<InvestmentOptionResponse, Partial<InvestmentOptionInfo>>({
      query(body) {
        return {
          url: ApiConstant.CREATE_INVESTMENT_OPTION,
          method: "POST",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["InvestmentOptions"]
    }),
    updateInvestmentOption: builder.mutation<InvestmentOptionResponse, InvestmentOption>({
      query({ ID, ...body }) {
        return {
          url: ApiConstant.UPDATE_INVESTMENT_OPTION(ID),
          method: "PATCH",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["InvestmentOptions"]
    }),
    getInvestmentOptionById: builder.query<InvestmentOptionInfoByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.INVESTMENT_OPTION_BY_ID(id)}`,
          method: "GET",
        };
      }
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.ADD_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    getActiveProvider: builder.query<ActiveProviderList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_PROVIDER,
          method: "GET",
        };
      },
      providesTags: ["ActiveProvider"],
    }),
    changeInvestmentOptiionStatus: builder.mutation<InvestmentOptionStatusResponse, InvestmentOptionStatus>({
      query: (body: InvestmentOptionStatus) => {
        const { ID, Enabled } = body;
        return {
          url: ApiConstant.CHANGE_INVESTMENT_OPTIONS_STATUS,
          method: "POST",
          body: { ID, Enabled }
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, Enabled, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            investmentOptionApi.util.updateQueryData(
              "getInvestmentOptionList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.Enabled = updatedInfo.data.Enabled;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      }
    }),
  }),
});

export const { useGetInvestmentOptionListQuery, useCreateInvestmentOptionMutation, useUpdateInvestmentOptionMutation, useGetInvestmentOptionByIdQuery, useAddCommentMutation, useGetActiveProviderQuery, useChangeInvestmentOptiionStatusMutation, useLazyDownloadCSVQuery } = investmentOptionApi;
