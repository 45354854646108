import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { ReactNode } from "react";
import { useNavigate } from "react-router";

// function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
//     event.preventDefault();
//     console.info('You clicked a breadcrumb.');
// }

export interface PageHeaderPropType {
  list: { name: string; href?: string | number }[];
  headerText: string;
  rightButton?: ReactNode;
}

const PageHeader = ({ list, headerText, rightButton }: PageHeaderPropType) => {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb-wrapper">
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {/* <div role="presentation" onClick={handleClick}> */}
          <Breadcrumbs aria-label="breadcrumb" role="contentinfo">
            {list.map((li) => {
              const linkref = li.href as string;
              if (li.href) {
                return (
                  <Link
                    underline="hover"
                    key={linkref}
                    color="inherit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(linkref)}
                    role="navigation"
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        navigate(linkref);
                      }
                    }}
                  >
                    {li.name}
                  </Link>
                );
              } else {
                return (
                  <Typography color="text.primary" key={li.name}>
                    {li.name}
                  </Typography>
                );
              }
            })}
          </Breadcrumbs>
          {/* </div> */}
          <Typography variant="h3" sx={{ mt: 1 }}>
            {headerText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
          {rightButton}
        </Grid>
      </Grid>
    </div>
  );
};

export default PageHeader;
