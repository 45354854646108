import { useCallback, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";

// assets
import {
  BellOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

// types
import { ThemeMode } from "types/config";
import useNotification from "hooks/useNotification";
import { useNavigate } from "react-router";
import {
  notificationApi,
  useGetNotificationListQuery,
} from "store/reducers/notification";
import React from "react";
import downloadFile from "helper/downloadFile";
import moment from "moment";
import useEscapeKey from "hooks/useEscapeKey";
// import useEscapeKey from "hooks/useEscapeKey";
import useAuth from "hooks/useAuth";

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const { read = 0, setRead } = useNotification();

  const { isLoggedIn } = useAuth();

  const { data } = useGetNotificationListQuery(
    {
      pageNo: 1,
      size: 5,
      searchText: "",
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !isLoggedIn,
    }
  );

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef?.current && anchorRef?.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  useEscapeKey(handleCloseMenu);

  const viewAll = () => {
    navigate(`/notifications`);
    setOpen(false);
  };

  const fileText = (errorCount: number, successCount: number, Type: string) => {
    if (errorCount <= 0 && successCount > 0) {
      return `Successfully Uploaded ${Type} File`;
    } else if (successCount <= 0 && errorCount > 0) {
      return `Failed ${Type} File Uploaded`;
    } else {
      return `Partially file upload`;
    }
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "grey.200" : "grey.300";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK ? "background.default" : "grey.100";

  const [trigger] = notificationApi.endpoints.downloadErrorFile.useLazyQuery();

  const downLoad = useCallback(
    async (id: string) => {
      const { data } = await trigger(id);
      const downloadLink = `data:text/csv;base64,${data?.data.fileData}`;
      const downloadName = data?.data.fileName || "download";
      downloadFile(downloadLink, downloadName);
    },
    [trigger]
  );

  // useEffect(() => {
  //   const handleEsc = (event: any) => {
  //     if (event.key === "Escape") {
  //       handleClose(event);
  //     }
  //   };

  //   document.addEventListener("keydown", handleEsc);

  //   return () => {
  //     document.removeEventListener("keydown", handleEsc);
  //   };
  // }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? "top" : "top-right"}
            sx={{ overflow: "hidden" }}
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {read > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => setRead(0)}
                          >
                            <CheckCircleOutlined
                              style={{ fontSize: "1.15rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      "& .MuiListItemButton-root": {
                        py: 0.5,
                        "&.Mui-selected": {
                          bgcolor: "grey.50",
                          color: "text.primary",
                        },
                        "& .MuiAvatar-root": avatarSX,
                        "& .MuiListItemSecondaryAction-root": {
                          ...actionSX,
                          position: "relative",
                        },
                      },
                    }}
                  >
                    {data?.data.map((res) => {
                      const {
                        ID,
                        SuccessCount,
                        ErrorCount,
                        Type,
                        CreatedDate,
                      } = res;
                      const errorCount = parseFloat(ErrorCount);
                      const successCount = parseFloat(SuccessCount);
                      return (
                        <React.Fragment key={ID}>
                          <ListItemButton selected={read > 0}>
                            {errorCount > 0 ? (
                              <Tooltip title="Click here to download the Error File">
                                <ListItemAvatar onClick={() => downLoad(ID)}>
                                  <Avatar
                                    sx={{
                                      color: "error.main",
                                      bgcolor: "error.lighter",
                                    }}
                                  >
                                    <DownloadOutlined />
                                  </Avatar>
                                </ListItemAvatar>
                              </Tooltip>
                            ) : (
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    color: "success.main",
                                    bgcolor: "success.lighter",
                                  }}
                                >
                                  <CheckOutlined />
                                </Avatar>
                              </ListItemAvatar>
                            )}
                            <ListItemText
                              primary={
                                <Typography variant="h6">
                                  {fileText(errorCount, successCount, Type)}
                                </Typography>
                              }
                              secondary={`Success: ${SuccessCount}, Failed: ${ErrorCount}`}
                            />
                            <ListItemSecondaryAction>
                              <Typography variant="caption" noWrap>
                                {CreatedDate && moment(CreatedDate).fromNow()}
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                          <Divider />
                        </React.Fragment>
                      );
                    })}

                    {/* <Divider />
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          <MessageOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            <Typography component="span" variant="subtitle1">
                              Aida Burg
                            </Typography>{' '}
                            commented your post.
                          </Typography>
                        }
                        secondary="5 August"
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          6:00 PM
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton> */}
                    {/* <Divider />
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          C
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            <Typography component="span" variant="subtitle1">
                              Cristina Danny
                            </Typography>{' '}
                            invited to join{' '}
                            <Typography component="span" variant="subtitle1">
                              Meeting.
                            </Typography>
                          </Typography>
                        }
                        secondary="Daily scrum meeting time"
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          9:10 PM
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton> */}
                    <Divider />
                    <ListItemButton
                      sx={{ textAlign: "center", py: `${12}px !important` }}
                      onClick={viewAll}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary">
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
