// third-party
// import { FormattedMessage } from "react-intl";

// project import
// import { useSelector } from "store";

// assets
import { DashboardOutlined } from "@ant-design/icons";

// type
// import { NavItemType } from "types/menu";

const icons = {
  dashboard: DashboardOutlined,
  // components: DashboardOutlined,
};

// ==============================|| MENU ITEMS - API ||============================== //

export const Menu = () => {
  // const { menu } = useSelector((state) => state.menu);
  // console.log(menu);

  const menu = {
    id: "group-dashboard",
    icon: icons.dashboard,
    type: "group",
    // title: <FormattedMessage id="dashboard" />,
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "/",
        icon: icons.dashboard,
      },
    ],
  };

  return menu;
};
