import { GuardProps } from 'types/auth';
import useMenuItems from 'hooks/useMenuItems';
import { Navigate } from 'react-router';
// ==============================|| AUTH GUARD ||============================== //

const PermissionGuard = (props: GuardProps) => {
    const { children, moduleName = "", permissionType = "CanView" } = props
    const { permission: handlePermission, menuItems = [] } = useMenuItems()
    const permission = handlePermission(moduleName)

    const isAccess = permission ? permission[permissionType] : false

    if (isAccess) {
        return children;
    }
    if (moduleName === "Dashboard") {
        const dashBoardAccess = menuItems.find(res => res.title === "Dashboard")
        if (!dashBoardAccess) {
            const firstAccessiblePage: any = menuItems[0]
            const DEFAULT_PATH = firstAccessiblePage?.children[0]?.url ?? "/"
            return <Navigate to={DEFAULT_PATH} replace />
        }
    }
    return <div>you don't have permission to access</div>

};

export default PermissionGuard;
