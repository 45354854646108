import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ViewChangeInfo,
  ChangeList,
  FilterParam,
} from "../../types/changelog";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const MASTER_DATACHANGELOG_API_REDUCER_KEY = "masterDataChangelogApi";

export const masterDataChangelogApi = createApi({
  reducerPath: MASTER_DATACHANGELOG_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["masterDataChangelog"],
  endpoints: (builder) => ({
    changelogList: builder.query<ChangeList, Partial<FilterParam>>({
      query: (body: FilterParam) => {
        // const { pageNo = 1, size = 30, EndDate, } = args;
        // const body = { pageNo, size, ...filter};
        return {
          url: ApiConstant.CHANGE_LOG_LIST,
          method: "POST",
          body
        //   body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["masterDataChangelog"],
    }),
    getChangeInfoById: builder.query<ViewChangeInfo, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.CHANGE_LOG_BY_ID(id)}`,
          method: "GET",
        };
      },
      providesTags: ["masterDataChangelog"],
    }),
  }),
});

export const {
  useChangelogListQuery,
  useGetChangeInfoByIdQuery,
} = masterDataChangelogApi;
