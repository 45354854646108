export const moduleConstant = {
  DASHBOARD: "Dashboard",
  EMPLOYERS: "Employers",
  ROLES_AND_PERMISSIONS: "Roles & Permissions",
  ADMIN_USERS: "Admin Users",
  EMPLOYEES: "Employees",
  PRODUCTS: "Products",
  WEBINARS: "Webinars",
  MASTER_DATA: "Master Data",
  JOURNEY: "Journey",
  RESPONSES: "Responses",
  SMART_ENGINE_PRODUCT: "Smart Engine: Products",
  CMS_PAGES: "CMS Pages",
  SCHEME_TYPES: "Scheme Types",
  SUPPLIERS: "Suppliers",
  PROVIDERS: "Providers",
  INVESTMENT_OPTIONS: "Investment Options",
  PENSION_FUNDS: "Pension Funds",
  PENSION_TYPES: "Pension Types",
  PAY_PERIODS: "Pay Periods",
  SCHEMES: "Schemes",
  AVC_PLANS: "AVC Plans",
  AUDIT_LOGS: "Audit Logs",
  HCT_CONFIG: "HCT Config",
  CONSENT: "Consent",
  MMM_PAGES: "MMM Pages",
  PRODUCT_PAGES: "Product Pages",
};
