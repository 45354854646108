import { createApi } from "@reduxjs/toolkit/query/react";
import {
    SchemeTypeListing,
    SearchParam,
    SchemeTypeInfo,
    CommentInfo,
    AddComment,
    SchemeStatus,
    SchemeStatusResponse,
    DownloadCSVResponse
} from "../../types/schemeType";
import { ApiConstant } from "../../constants/ApiConstants";
import { current } from "@reduxjs/toolkit";
import baseQuery from "./baseQuery";

export const SCHEME_TYPE_API_REDUCER_KEY = "schemeTypeApi";

export const schemeTypeApi = createApi({
    reducerPath: SCHEME_TYPE_API_REDUCER_KEY,
    baseQuery: baseQuery,
    tagTypes: ["SchemeTypes"],
    endpoints: (builder) => ({
        getSchemeTypeList: builder.query<SchemeTypeListing, Partial<SearchParam>>({
            query: (args: SearchParam) => {
                const { pageNo = 1, size = 30, searchText = "", filter } = args;
                const body = { pageNo, size };
                return {
                    url: ApiConstant.SCHEME_TYPES,
                    method: "POST",
                    body: filter?.length ? { ...body, filter } : { ...body, searchText },
                };
            },
            providesTags: ["SchemeTypes"],
        }),
        getSchemeById: builder.query<SchemeTypeInfo, string>({
            query: (id: string) => {
                return {
                    url: `${ApiConstant.SCHEME_BY_ID(id)}`,
                    method: "GET",
                };
            },
        }),
        addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
            query: (body) => {
                return {
                    url: ApiConstant.ADD_COMMENT,
                    method: "POST",
                    body,
                };
            },
            transformErrorResponse: (response: {
                data: { error: string; message: string };
                status: number;
            }): string => response.data.error,
        }),
        changeSchemeStatus: builder.mutation<SchemeStatusResponse, SchemeStatus>({
            query: (body: SchemeStatus) => {
              const { ID, Enabled } = body;
              return {
                url: ApiConstant.CHANGE_SCHEME_TYPE_STATUS,
                method: "POST",
                body: { ID, Enabled }
              };
            },
            // invalidatesTags: ['Employers'],
            // eslint-disable-next-line no-empty-pattern
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
              try {
                const { ID, Enabled, ...rest } = args;
                const { data: updatedInfo } = await queryFulfilled;
                console.log("Data", updatedInfo);
                const patchedResult = dispatch(
                    schemeTypeApi.util.updateQueryData(
                    "getSchemeTypeList",
                    rest,
                    (draft) => {
                      console.log("UUData", draft.data);
                      const objIndex = draft.data.findIndex(
                        (item, index) => item.ID === updatedInfo.data.ID
                      );
                      if (objIndex !== -1) {
                        const info = draft.data[objIndex];
                        info.Enabled = updatedInfo.data.Enabled;
                        draft.data[objIndex] = info;
                      }
                      console.log("Draft Info", current(draft.data));
                    }
                  )
                );
                console.log("Pathched", patchedResult);
              } catch (error) {
                console.log("Error", error);
              }
            },
        }),
        downloadCSV: builder.query<DownloadCSVResponse, string>({
          query: (type: string) => {
            return {
              url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
              method: "GET",
            };
          }
        }),
    }),
});

export const {
    useGetSchemeTypeListQuery,
    useGetSchemeByIdQuery,
    useAddCommentMutation,
    useChangeSchemeStatusMutation,
    useLazyDownloadCSVQuery
} = schemeTypeApi;
