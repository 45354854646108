import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import { CmsPageList } from "types/cms-pages";

export const CMS_API_REDUCER_KEY = "cmsApi";

export const cmsApi = createApi({
  reducerPath: CMS_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["CmsPages"],
  endpoints: (builder) => ({
    getAllCmsPagesList: builder.query<CmsPageList, {}>({
      query: () => {
        return {
          url: ApiConstant.CMS_LISTING,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetAllCmsPagesListQuery } = cmsApi;
