import React, { Fragment, useState, useCallback } from "react";

// material-ui
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  Typography,
  // CircularProgress,
  Button,
  Tooltip,
  Skeleton,
  // Box,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import {
  useTable,
  useFilters,
  usePagination,
  Column,
  HeaderGroup,
  Row,
  Cell,
  TableRowProps,
  useExpanded,
  useSortBy,
} from "react-table";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import {
  HidingSelect,
  EcoTablePagination,
  HeaderSort,
  DraggableRow,
} from "components/third-party/ReactTable";
import { SearchFilter } from "utils/react-table";
import { FilterOutlined, DownloadOutlined } from "@ant-design/icons";
import MainCard from "./MainCard";
import ScrollX from "./ScrollX";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import update from "immutability-helper";
import LottieLoader from "./LottieLoader";
import { IconButton } from "@mui/material";
// ==============================|| REACT TABLE ||============================== //

// interface Props {
//   getHeaderProps: (column: HeaderGroup) => {};
// }

function ReactSubTable({
  columns,
  data,
  loading,
}: {
  columns: Column[];
  data: {
    Field: string;
    newValue: any;
    oldValue: any;
  }[];
  loading: boolean;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  if (loading) {
    return (
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {[0, 1, 2].map((item: number) => (
            <TableRow key={item}>
              {[0, 1, 2, 3, 4, 5].map((col: number) => (
                <TableCell key={col}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup) => (
              <TableCell
                {...column.getHeaderProps([{ className: column.className }])}
              >
                {column.render("Header")}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: Cell) => (
                <TableCell
                  sx={{ verticalAlign: "top" }}
                  {...cell.getCellProps([{ className: cell.column.className }])}
                >
                  {cell.render("Cell")}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

// ==============================|| SUB ROW - ASYNC DATA ||============================== //

function SubRowAsync({
  row,
  columns,
  accessor,
  header,
}: {
  row: Row;
  columns: Column[];
  accessor: string;
  header: string;
}) {
  const theme = useTheme();
  const [loading] = useState(false);
  const info: any = row.original || {};

  const backColor = alpha(theme.palette.primary.lighter, 0.1);

  return (
    <TableRow
      sx={{
        bgcolor: backColor,
        "&:hover": { bgcolor: `${backColor} !important` },
      }}
    >
      <TableCell colSpan={8} sx={{ p: 2.5 }}>
        <MainCard
          title={header}
          content={false}
          sx={{ ml: { xs: 2.5, sm: 5, md: 6, lg: 10, xl: 12 } }}
        >
          <ReactSubTable
            columns={columns}
            data={info[accessor]}
            loading={loading}
          />
        </MainCard>
      </TableCell>
    </TableRow>
  );
}

function ReactTable({
  columns,
  data,
  subFields,
  globalFilter,
  setGlobalFilter,
  isFetching,
  pageNumber,
  pageSize,
  totalRecords = 0,
  onChangePageIndex,
  setPageSize,
  isDownloadEnable,
  children,
  open,
  handleToggle,
  handleDownload,
  VisibleColumn,
  getHeaderProps,
  setVisibleColumns,
  handleSort,
  onDrag,
  dragable = false,
  dragDisable,
  customCell,
}: {
  columns: Column[];
  subcolumns?: Column[];
  data: any[];
  subFields?: { header: string; accessor: string; columns: Column[] };
  globalFilter?: string;
  setGlobalFilter?: (value: string) => void;
  onChangePageIndex?: (value: number) => void;
  setPageSize?: (value: number) => void;
  isFetching?: boolean;
  totalRecords?: number;
  isDownloadEnable?: boolean;
  pageNumber?: number;
  pageSize?: number;
  children?: React.ReactNode;
  open?: boolean;
  handleToggle?: () => void;
  handleDownload?: () => void;
  VisibleColumn?: any[];
  getHeaderProps?: any;
  setVisibleColumns?: (arr: string[]) => void;
  handleSort?: (column: HeaderGroup) => void;
  onDrag?: (dt: typeof data) => void;
  dragable?: boolean;
  dragDisable?: (dt: any) => boolean;
  customCell?: React.ReactNode;
}) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const getRowId = useCallback((row: any) => row.id, []);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = data[dragIndex];
    if (onDrag) {
      onDrag(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      );
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    setHiddenColumns,
    allColumns,
    state: { hiddenColumns },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: {
        pageIndex: 0,
        pageSize: pageSize,
        hiddenColumns: columns
          .filter((col: Column<{}>) =>
            VisibleColumn?.includes(col.accessor as string)
          )
          .map((col) => col.accessor) as string[],
        // sortBy: getHeaderProps ? [sortBy] : [],
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    getRowId
  );

  let headers: LabelKeyObject[] = [];
  allColumns.map((item) => {
    if (!hiddenColumns?.includes(item.id)) {
      headers.push({ label: item.Header as string, key: item.id });
    }
    return item;
  });

  const onChangeColumnVisbility = (arr: string[]) => {
    setHiddenColumns(arr);
    setVisibleColumns && setVisibleColumns(arr);
  };

  const renderRowSubComponent = useCallback(
    ({ row }: { row: Row; rowProps: TableRowProps }) =>
      subFields ? (
        <SubRowAsync
          row={row}
          columns={subFields.columns}
          header={subFields.header}
          accessor={subFields.accessor}
        />
      ) : null,
    [subFields]
  );

  return (
    <Stack>
      {(globalFilter !== undefined || handleToggle) && (
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={3}
          justifyContent={
            globalFilter !== undefined && setGlobalFilter !== undefined
              ? "space-between"
              : "flex-end"
          }
          alignItems="center"
          sx={{ p: 3 }}
        >
          {globalFilter !== undefined && setGlobalFilter !== undefined && (
            <SearchFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
            />
          )}
          <div className="role-header">
            <Stack
              direction={matchDownSM ? "column" : "row"}
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mr: 2 }}
            >
              <HidingSelect
                hiddenColumns={hiddenColumns!}
                setHiddenColumns={onChangeColumnVisbility}
                allColumns={allColumns}
              />
            </Stack>
            {handleToggle && (
              <Button
                variant="contained"
                startIcon={<FilterOutlined />}
                size="medium"
                onClick={handleToggle}
              >
                Filter
              </Button>
            )}
            {isDownloadEnable && (
              <Tooltip title="CSV Export">
                <IconButton
                  onClick={handleDownload}
                  className="download-btn"
                  color="primary"
                  sx={{ ml: "10px" }}
                >
                  <DownloadOutlined
                    style={{
                      fontSize: "24px",
                      color: "gray",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Stack>
      )}
      <ScrollX>
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <Table {...getTableProps()} size="small">
            <TableHead sx={{ borderTopWidth: 1 }}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {getHeaderProps && <TableCell>ID</TableCell>}
                  {dragable && <TableCell />}
                  {headerGroup.headers.map((column: HeaderGroup) => (
                    <TableCell
                      {...column.getHeaderProps([
                        { className: column.className },
                        getHeaderProps ? getHeaderProps(column) : {},
                      ])}
                    >
                      {/* {column.render("Header")} */}
                      {getHeaderProps ? (
                        <HeaderSort column={column} />
                      ) : handleSort ? (
                        <HeaderSort
                          column={column}
                          handleSort={() => handleSort(column)}
                        />
                      ) : (
                        column.render("Header")
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={13}>
                    <Typography
                      component="div"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="400px"
                    >
                      {/* <CircularProgress sx={{ color: "#391474" }} size={80} /> */}
                      <LottieLoader />
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {page?.length > 0 ? (
                    <>
                      {rows.map((row: Row, index) => {
                        const original: any = row.original || {};
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        const number = pageNumber ?? 1;
                        const size = pageSize ?? 1;
                        if (dragable && dragDisable) {
                          return (
                            <Fragment key={index}>
                              <DraggableRow
                                disabled={dragDisable(original)}
                                moveRow={moveRow}
                                index={index}
                                {...row.getRowProps()}
                              >
                                {getHeaderProps && (
                                  <TableCell>
                                    {(number - 1) * size + (index + 1)}
                                  </TableCell>
                                )}
                                {row.cells.map((cell: Cell) => (
                                  <TableCell
                                    {...cell.getCellProps([
                                      { className: cell.column.className },
                                    ])}
                                  >
                                    {cell.render("Cell")}
                                  </TableCell>
                                ))}
                              </DraggableRow>
                              {subFields &&
                                row.isExpanded &&
                                renderRowSubComponent({ row, rowProps })}
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment key={index}>
                              <TableRow {...row.getRowProps()}>
                                {getHeaderProps && (
                                  <TableCell>
                                    {(number - 1) * size + (index + 1)}
                                  </TableCell>
                                )}
                                {row.cells.map((cell: Cell) => (
                                  <TableCell
                                    {...cell.getCellProps([
                                      { className: cell.column.className },
                                    ])}
                                  >
                                    {cell.render("Cell")}
                                  </TableCell>
                                ))}
                              </TableRow>
                              {subFields &&
                                row.isExpanded &&
                                renderRowSubComponent({ row, rowProps })}
                            </Fragment>
                          );
                        }
                      })}
                    </>
                  ) : (
                    !customCell && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            getHeaderProps || dragable
                              ? columns?.length + 1
                              : columns?.length
                          }
                        >
                          <Typography variant="h3" textAlign="center" my={5}>
                            No Records Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  {customCell && customCell}
                </>
              )}

              {onChangePageIndex && setPageSize && pageNumber && pageSize && (
                <TableRow>
                  <TableCell
                    sx={{ p: 2 }}
                    colSpan={
                      getHeaderProps || dragable
                        ? columns?.length + 1
                        : columns?.length
                    }
                  >
                    <EcoTablePagination
                      gotoPage={onChangePageIndex}
                      rows={rows}
                      setPageSize={setPageSize}
                      pageIndex={pageNumber}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DndProvider>
      </ScrollX>

      {children && children}
    </Stack>
  );
}

export default ReactTable;
