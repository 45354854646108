// material-ui
import { useState, useEffect } from 'react'

import { useTheme } from '@mui/material/styles';

// third-party
import SyntaxHighlighter from 'react-syntax-highlighter';
// import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// types
import { ThemeMode } from 'types/config';

// ==============================|| CODE HIGHLIGHTER ||============================== //

export default function SyntaxHighlight({ children, ...others }: { children: string }) {
  const theme = useTheme();

  const [dark, setDark] = useState<any>()
  const [light, serLight] = useState<any>()

  useEffect(() => {
    import('react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark')
    .then(mod => setDark(mod.default));
    import('react-syntax-highlighter/dist/esm/styles/hljs/a11y-light')
    .then(mod => serLight(mod.default));
  },[])

  return (
    <SyntaxHighlighter
      language="javascript"
      showLineNumbers
      style={theme.palette.mode === ThemeMode.DARK ? light : dark}
      {...others}
    >
      {children}
    </SyntaxHighlighter>
  );
}
