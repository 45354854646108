import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import { GetModuleResponse, ModuleAccessResponse } from "types/modules";
import baseQuery from "./baseQuery";

export const EMPLOYER_API_REDUCER_KEY = "moduleApi";

export const moduleApi = createApi({
  reducerPath: EMPLOYER_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Modules", "ModuleAccess"],
  endpoints: (builder) => ({
    getModuleList: builder.query<GetModuleResponse, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_MODULES,
          method: "GET",
        };
      },
      providesTags: ["Modules"],
    }),
    getModuleAccessInfo: builder.query<ModuleAccessResponse, {}>({
      query: () => {
        return {
          url: ApiConstant.ACCESS_MODULES,
          method: "GET",
        };
      },
      providesTags: ["ModuleAccess"],
    }),
  }),
});

export const {
  useGetModuleListQuery,
  useLazyGetModuleAccessInfoQuery,
  useGetModuleAccessInfoQuery
} = moduleApi;
