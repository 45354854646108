import { InputLabel, FormControl, Select, MenuItem, FormHelperText, Stack, SelectChangeEvent } from "@mui/material"
import { FormFieldProps } from ".";
import { ChangeEvent } from "react";

export type SelectControlProps = FormFieldProps & {
    value: string;
    onChange: (event: ChangeEvent<any> | SelectChangeEvent<string>) => void;
    options: { value: string | number, label: string }[]
}

const SelectControl = (props: SelectControlProps) => {
    const { label, required, id, name, value, onChange, error, helperText, disabled, options } = props

    return (
        <Stack spacing={1}>
            {label && <InputLabel htmlFor={id} required={required}>
                {label}
            </InputLabel>}
            <FormControl>
                <Select disabled={disabled} error={error}
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    {options.map(option => <MenuItem key={option.value}
                        value={option.value}>{option.label}</MenuItem>)}
                </Select>
                {error && (
                    <FormHelperText
                        error
                        id={`standard-weight-helper-text-${id}`}
                    >
                        {" "}
                        {helperText}{" "}
                    </FormHelperText>
                )}
            </FormControl>
        </Stack>
    )
}

export default SelectControl