import { Link } from "react-router-dom";
import { To } from "history";

// material-ui
import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/system";

// project import
import Logo from "./LogoMain";
import LogoIcon from "./LogoIcon";
import { APP_DEFAULT_PATH } from "config";
// import backgroundimage from "../../assets/images/mmm-logo-bg.png";


// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <div className={`${!isIcon && "logo-alignment"}`}>
    {/* style={{ marginLeft: "-25px" }} */}
    <ButtonBase
      disableRipple
      component={Link}
      to={!to ? APP_DEFAULT_PATH : to}
      sx={sx}
      className="brandlogo"
    >
      {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
    </ButtonBase>
  </div>
);

export default LogoSection;
