import { Grid } from "@mui/material";
import PageHeader from "components/@extended/PageHeader";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import SequenceForm from "sections/product-sequence/SequenceForm";
import { useCreateProductSequenceMutation } from "store/reducers/smartEngineProduct";
import { CreateSmartProductSequencePayLoad } from "types/smart-engine-product";
import { isApiResponse } from "helper/isApiResponse";

const navigation = [
  { name: "HCT Config", href: "/hct-config/smart-engine-products/list" },
  { name: "Smart Engine: Products" },
];

const AddSequence = () => {
  const navigate = useNavigate();
  const [createSequence, { isSuccess, isError, error, isLoading }] =
    useCreateProductSequenceMutation();

  const onSubmit = async (data: CreateSmartProductSequencePayLoad) => {
    await createSequence(data);
  };

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Sequence Created Successfully", {
        variant: "success",
      });
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isError) {
      if (isApiResponse(error)) {
        if (error.data.error) {
          enqueueSnackbar(error.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong. Please try again later", {
            variant: "error",
          });
        }
      }
    }
  }, [error, isError]);

  return (
    <Grid container spacing={2} sx={{ display: "block !important" }}>
      <Grid item xs={12}>
        <PageHeader list={navigation} headerText={"Add New Sequence"} />
      </Grid>
      <Grid item xs={12}>
        <SequenceForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          isSubmitting={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default AddSequence;
