import { createApi } from "@reduxjs/toolkit/query/react";
import { PensionTypeList, SearchParam, CreatePensionTypeResponse, CreatePensionType, UpdatePensionType, PensionTypeByIdResponse, CommentInfo, AddComment, ActivePensionFundist, DownloadCSVResponse } from '../../types/pensionTypes'
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const PENSION_TYPE_API_REDUCER_KEY = "pensionTypesApi";

export const pensionTypeApi = createApi({
  reducerPath: PENSION_TYPE_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["PensionType", "ActivePensionFund"],
  endpoints: (builder) => ({
    getPensionTypeList: builder.query<PensionTypeList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.PENSION_TYPE,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["PensionType"],
    }),
    createPensionType: builder.mutation<CreatePensionTypeResponse, Partial<CreatePensionType>>({
      query(body) {
        return {
          url: ApiConstant.CREATE_PENSION_TYPE,
          method: "POST",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["PensionType"]
    }),
    updatePensionTypeOption: builder.mutation<CreatePensionTypeResponse, UpdatePensionType>({
      query({ ID, ...body }) {
        return {
          url: ApiConstant.UPDATE_PENSION_TYPE(ID),
          method: "PATCH",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["PensionType"]
    }),
    getPensionTypeById: builder.query<PensionTypeByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PENSION_TYPE_BY_ID(id)}`,
          method: "GET",
        };
      }
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.ADD_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    getActivePensionFundList: builder.query<ActivePensionFundist, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_PENSION_FUNDS,
          method: "GET",
        };
      },
      providesTags: ["ActivePensionFund"],
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      }
    }),
  }),
});

export const { useGetPensionTypeListQuery, useCreatePensionTypeMutation, useUpdatePensionTypeOptionMutation, useGetPensionTypeByIdQuery, useAddCommentMutation, useGetActivePensionFundListQuery, useLazyDownloadCSVQuery } = pensionTypeApi;
