import { InputLabel, TextField, Stack, FilledInputProps } from '@mui/material'
import { FormFieldProps } from '.';

export interface InputControlProps extends FormFieldProps {
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type?: string;
  InputProps?: Partial<FilledInputProps>
}

const InputControl = (props: InputControlProps) => {
  const { label, required, id, name, onBlur, value, onChange, error, helperText, type, disabled, InputProps} = props
  return (
    <Stack spacing={1}>
      {
        label &&
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
      }
      <TextField type={type} disabled={disabled} InputProps={InputProps}
        id={id}
        name={name}
        onBlur={onBlur}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
    </Stack>
  )
}

export default InputControl