import { FormControl, FormControlLabel, Stack, FormHelperText, Checkbox, InputBaseProps } from '@mui/material'
import { ChangeEvent } from 'react';

export type CheckControlProps = {
    label?: string;
    required?: boolean;
    id: string;
    name: string;
    onBlur?: InputBaseProps['onBlur'];
    error?: boolean;
    helperText?: string | React.ReactNode;
    disabled?: boolean;
    value: boolean;
    onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const CheckControl = (props: CheckControlProps) => {
    const { label, required, id, name, value, onChange, error, helperText, disabled } = props
    return (
        <Stack spacing={1}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <FormControlLabel
                    required={required}
                    control={
                        <Checkbox id={id} disabled={disabled}
                            color="primary"
                            name={name}
                            checked={value}
                            onChange={onChange}
                        />
                    }
                    label={label}
                />
                {error && (
                    <FormHelperText
                        error={error}
                    >
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </Stack>
    )
}

export default CheckControl