import { useContext } from 'react';

import NotificationContext from 'contexts/NotificationContext';

const useNotification = () => {
  const notification = useContext(NotificationContext);

  if (!notification) throw new Error('context must be use inside provider');

  return notification;
};

export default useNotification;
