import MainCard from "components/MainCard";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Grid,
  Stack,
  Button,
  InputLabel,
  Typography,
  FormControl,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  UpdateSmartProductSequencePayLoad,
  smartEngineProduct,
} from "types/smart-engine-product";
import ReactTable from "components/ReactTable";
import ScrollX from "components/ScrollX";
import {
  useGetAllActiveThemesQuery,
  useGetAllActiveProductsQuery,
} from "store/reducers/smartEngineProduct";
import { Column } from "react-table";
import { DeleteFilled } from "@ant-design/icons";
import { useModal } from "mui-modal-provider";
import Avatar from "components/@extended/Avatar";
import { CloseCircleFilled } from "@ant-design/icons";
import CustomDraggableReactTable from "components/CustomDraggableReactTable";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import FormField from "components/formField";
import { isEmpty } from "lodash";
import ConfirmDialog from "components/ConfirmationDialog";

type RuleBuilder = {
  ThemeID: string | null;
  Condition: string | null;
  Score: number | null;
};

type RowInfo = {
  row: any;
};

type Product = { id: string; ProductName: string; sequence: number };

type SequenceFormProps = {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  info?: smartEngineProduct;
  isSubmitting: boolean;
};

const scoreEquationList = [
  { label: "Is greater than", value: "Is greater than" },
  { label: "Is less than", value: "Is less than" },
  { label: "Equal to", value: "Equal to" },
];

function SequenceForm({
  onCancel,
  onSubmit,
  info,
  isSubmitting,
}: SequenceFormProps) {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [ProductListString, setProductListString] = useState("");
  const [productListIds, setproductListIds] = useState<string[]>([]);

  const theme = useTheme();

  const { data: SectionNames, isFetching: SectionNamesGettingFetched } =
    useGetAllActiveThemesQuery("", {
      refetchOnMountOrArgChange: true,
    });

  const { data: Products, isLoading: isLoadingProducts } =
    useGetAllActiveProductsQuery("", {
      refetchOnMountOrArgChange: true,
    });

  const [RuleBuilders, setRuleBuilders] = useState<RuleBuilder[]>([
    {
      Condition: "",
      ThemeID: "",
      Score: null,
    },
  ]);

  const validationSchema = Yup.object().shape({
    ThemeID: Yup.string().required("Theme is Required !"),
    Condition: Yup.string().required("Condition is Required !"),
    Score: Yup.string().required("Score is Required !"),
  });

  const initialValues: RuleBuilder = {
    ThemeID: "",
    Condition: "",
    Score: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const newV = [...RuleBuilders];
      newV.pop();
      newV.push(values);
      newV.push({
        Condition: "",
        ThemeID: "",
        Score: null,
      });

      setRuleBuilders(newV);
      resetForm();
    },
  });

  const { values, setValues, touched, errors, handleSubmit, resetForm } =
    formik;

  const [productsList, setproductsList] = useState<Product[]>([]);

  function sortArrayByIdList(dataArr: any[], idList: string[]): any[] {
    const idToObjectMap = new Map(
      dataArr.map((obj: { ID: any }) => [obj.ID, obj])
    );

    return idList.map((id: string) => idToObjectMap.get(id));
  }

  useEffect(() => {
    let data = Products?.data || [];

    if (info && data.length > 0) {
      const sortedArr = sortArrayByIdList(data, info.ProductSequence);
      data = sortedArr;
    }

    const list: { id: string; ProductName: string; sequence: number }[] =
      data.map((pr: { ID: any; ProductName: any }, index: number) => ({
        id: pr.ID,
        ProductName: pr.ProductName,
        sequence: index + 1,
      })) || [];

    setproductsList(list);
  }, [Products, info]);

  useEffect(() => {
    const d: RuleBuilder[] =
      info?.ProductSequenceRuleBuilders.map((rb) => ({
        Condition: rb.Condition,
        ThemeID: rb.ThemeID,
        Score: rb.Score ? parseInt(rb.Score) : null,
      })) || [];

    d.push({
      Condition: "",
      ThemeID: "",
      Score: null,
    });

    setRuleBuilders(d);
  }, [info]);

  const sectionNameList: { value: string; label: string }[] = useMemo(() => {
    const list: { value: string; label: string }[] =
      SectionNames?.data.map((sn: { ID: string; ThemeName: string }) => ({
        value: sn.ID,
        label: sn.ThemeName,
      })) ?? [];

    return list || [];
  }, [SectionNames]);

  const sectionNameListOptions: { value: string; label: string }[] =
    useMemo(() => {
      return sectionNameList.filter(
        (sN) => !RuleBuilders.find((rb) => rb.ThemeID === sN.value)
      );
    }, [RuleBuilders, sectionNameList]);

  useEffect(() => {
    let list = "";

    for (let a = 0; a < productsList.length; a++) {
      const p = productsList[a];

      list =
        list +
        (a < productsList.length - 1
          ? `${p.ProductName}, `
          : `${p.ProductName}`);
    }

    setProductListString(list);

    let l = [];

    for (let a = 0; a < productsList.length; a++) {
      const p = productsList[a];

      l.push(p.id);
    }

    setproductListIds(l);
  }, [productsList, info]);

  const SectionNameList = useCallback(
    (props: RowInfo) => {
      const { row } = props;

      if (row.index === RuleBuilders.length - 1) {
        return (
          <FormField
            control="select-async"
            label="Select Section Name"
            name="SectionName"
            id="SectionName"
            loading={SectionNamesGettingFetched}
            options={sectionNameListOptions}
            onBlur={() => {}}
            onChange={(newValue) =>
              setValues({
                ...values,
                ThemeID: newValue?.value ?? "",
              })
            }
            value={
              sectionNameList.find((val) => val.value === values.ThemeID) ??
              null
            }
            error={Boolean(errors.ThemeID?.toString())}
            helperText={touched.ThemeID && errors.ThemeID?.toString()}
          />
        );
      }

      return (
        <Typography>
          {
            sectionNameList.find((sn) => sn.value === row.original.ThemeID)
              ?.label
          }
        </Typography>
      );
    },
    [
      RuleBuilders.length,
      SectionNamesGettingFetched,
      errors.ThemeID,
      sectionNameList,
      sectionNameListOptions,
      setValues,
      touched.ThemeID,
      values,
    ]
  );

  const ScoreEquationList = useCallback(
    (props: RowInfo) => {
      const { row } = props;

      if (row.index === RuleBuilders.length - 1) {
        return (
          <FormField
            control="select-async"
            label="Select Section Equation"
            name="SectionEquation"
            id="SectionEquation"
            loading={false}
            options={scoreEquationList}
            onBlur={
              () => {}
              // setFieldTouched(
              //     "Condition",
              //     !values.Condition ?? true
              // )
            }
            onChange={(newValue) =>
              setValues({
                ...values,
                Condition: newValue?.value ?? "",
              })
            }
            value={
              scoreEquationList.find((val) => val.value === values.Condition) ??
              null
            }
            error={Boolean(errors.Condition?.toString())}
            helperText={touched.Condition && errors.Condition?.toString()}
          />
        );
      }

      return <Typography>{row.original.Condition}</Typography>;
    },
    [
      RuleBuilders.length,
      errors.Condition,
      setValues,
      touched.Condition,
      values,
    ]
  );

  const activeScoreList = useMemo(
    () => [
      { label: "0%", value: "0" },
      { label: "10%", value: "10" },
      { label: "20%", value: "20" },
      { label: "30%", value: "30" },
      { label: "40%", value: "40" },
      { label: "50%", value: "50" },
      { label: "60%", value: "60" },
      { label: "70%", value: "70" },
      { label: "80%", value: "80" },
      { label: "90%", value: "90" },
      { label: "100%", value: "100" }
    ],
    []
  );

  const ScoreValueList = useCallback(
    (props: RowInfo) => {
      const { row } = props;

      if (row.index === RuleBuilders.length - 1) {
        return (
          <FormField
            control="select-async"
            label="Select Score"
            name="Score"
            id="Score"
            loading={false}
            options={activeScoreList}
            onBlur={
              () => {}
              // setFieldTouched(
              //     "Score",
              //     !values.Score ?? true
              // )
            }
            onChange={(newValue) =>
              setValues({
                ...values,
                Score: newValue?.value ? parseInt(newValue?.value) : null,
              })
            }
            value={
              activeScoreList.find(
                (val) => val.value === values.Score?.toString()
              ) ?? null
            }
            error={touched.Score && Boolean(errors.Score?.toString())}
            helperText={touched.Score && errors.Score?.toString()}
          />
        );
      }

      return <Typography>{row.original.Score}%</Typography>;
    },
    [
      RuleBuilders.length,
      activeScoreList,
      errors.Score,
      setValues,
      touched.Score,
      values,
    ]
  );

  const deleteFun = useCallback(
    (index: number) => {
      const data = [...RuleBuilders];
      data.splice(index, 1);
      // setsequenceData(data)
      setRuleBuilders(data);
    },
    [RuleBuilders]
  );

  const Actions = useCallback(
    (props: RowInfo) => {
      const { row } = props;
      const { index } = row;

      if (row.index === RuleBuilders.length - 1) {
        return (
          <Stack direction="row" alignItems="center" spacing={0}>
            <Tooltip title="Add">
              <Button size="small" onClick={() => handleSubmit()}>
                Add
              </Button>
            </Tooltip>
          </Stack>
        );
      }

      return (
        <Stack direction="row" alignItems="center" spacing={0}>
          <Tooltip title="View">
            <IconButton
              color="secondary"
              onClick={() => {
                onTapDeleteRule(index);
              }}
              className="view-icon"
            >
              <DeleteFilled twoToneColor={theme.palette.secondary.main} />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    },
    [RuleBuilders.length, deleteFun, handleSubmit, theme.palette.secondary.main]
  );

  const SequenceColumn = useCallback((props: RowInfo) => {
    const { row } = props;
    const { index } = row;

    return <Typography>{index + 1}</Typography>;
  }, []);

  const sequenceUpdateColumns: Column[] = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "ProductName",
      },
      {
        Header: "Sequence",
        Cell: SequenceColumn,
      },
    ],
    [SequenceColumn]
  );

  const openModal = useCallback(() => {
    const modal = showModal(CustomDraggableReactTable, {
      title: "Define Product Display Sequence",
      closeButton: (
        <Avatar
          onClick={() => {
            modal.hide();
          }}
        >
          <CloseCircleFilled />
        </Avatar>
      ),
      dragFun: (data: any[]) => {
        modal.update({
          data: data,
        });
      },
      data: productsList,
      isFetching: isLoadingProducts,
      onUpdate: (data: any[]) => {
        setproductsList(data);

        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      sequenceUpdateColumns,
    });
  }, [isLoadingProducts, productsList, sequenceUpdateColumns, showModal]);

  const onTapDeleteRule = useCallback(
    (index: number) => {
      const modal = showModal(ConfirmDialog, {
        title: "Delete Rule",
        body: "Are you sure you want to delete this rule?",
        icon: (
          <Avatar
            color="error"
            sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
          >
            <DeleteFilled />
          </Avatar>
        ),
        onTapYes: () => {
          deleteFun(index);
          modal.hide();
        },
        onTapNo: () => {
          modal.hide();
        },
      });
    },
    [deleteFun, showModal]
  );

  const columns: Column[] = useMemo(
    () => [
      {
        Header: "Section Name",
        // accessor: "ProductNames",
        Cell: SectionNameList,
      },
      {
        Header: "Score Equation",
        // accessor: "ProductNames",
        Cell: ScoreEquationList,
      },
      {
        Header: "Score value",
        // accessor: "ProductNames",
        Cell: ScoreValueList,
      },
      {
        Header: "Actions",
        // accessor: "ProductNames",
        Cell: Actions,
      },
    ],
    [Actions, ScoreEquationList, ScoreValueList, SectionNameList]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <MainCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="Name">
                  Product Display Sequence
                  <Typography component="span" color="#ff0000">
                    *
                  </Typography>
                </InputLabel>
                <Grid xs={6}>
                  <FormControl fullWidth>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={openModal}
                      style={{
                        minHeight: 40,
                      }}
                    >
                      {ProductListString}
                    </Button>
                  </FormControl>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          {!info?.IsDefault && (
            <Stack
              spacing={1}
              style={{
                marginTop: 15,
              }}
            >
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="Name">
                    Display Rule builder
                    <Typography component="span" color="#ff0000">
                      *
                    </Typography>
                    <Typography component="span" variant="body2">
                      {" "}
                      (Note: The product sequence will be displayed on the
                      tailored employee dashboard if all of the below conditions
                      are met)
                    </Typography>
                  </InputLabel>
                  <Grid xs={12}>
                    <ScrollX>
                      <ReactTable columns={columns} data={RuleBuilders} />
                    </ScrollX>
                  </Grid>
                </Stack>
              </Grid>
            </Stack>
          )}
        </MainCard>

        <Grid container className="position-fixed" sx={{ mt: "10px" }}>
          <Grid item xs={12} sx={{ p: "10px 20px 20px 20px" }}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"space-between"}
              alignContent={"center"}
            >
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  if (isSubmitting) {
                    return;
                  }

                  if (
                    !isEmpty(errors) &&
                    (!isEmpty(values.Condition) ||
                      !isEmpty(values.Score) ||
                      !isEmpty(values.ThemeID))
                  ) {
                    enqueueSnackbar(
                      "Please fill all the fields before submitting !",
                      { variant: "error" }
                    );
                    return;
                  }

                  if (
                    !isEmpty(values.Condition) &&
                    !isEmpty(values.Score) &&
                    !isEmpty(values.ThemeID)
                  ) {
                    enqueueSnackbar("Please add the rule!", {
                      variant: "info",
                    });
                    return;
                  }

                  if (RuleBuilders.length === 1) {
                    enqueueSnackbar("Please add rules!", { variant: "error" });
                    return;
                  }

                  let arr = [...RuleBuilders];

                  arr.pop();

                  const data: Partial<UpdateSmartProductSequencePayLoad> = {
                    productSequence: productListIds,
                    productThemeRules: arr,
                  };

                  if (!isEmpty(info)) {
                    data.ID = info.ID;
                  }

                  onSubmit(data);
                }}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default SequenceForm;
