// action - state management
import { NotificationActionProps, NotificationList, NotificationProps } from 'types/notification';
import { DELETETOKEN, GETTOKEN, READCOUNT } from './actions';
import baseQuery from './baseQuery';
import { createApi } from "@reduxjs/toolkit/query/react";
import { DownloadErrorFile, SearchParam} from "../../types/notification";
import { ApiConstant } from "../../constants/ApiConstants";
// initial state
export const initialState: NotificationProps = {
    token: null,
    read: 0,
}

// ==============================|| AUTH REDUCER ||============================== //

const notificationReducer = (state = initialState, action: NotificationActionProps) => {
    switch (action.type) {
        case GETTOKEN: {
            const { token } = action.payload!;
            return {
                ...state,
                token
            };
        }
        case DELETETOKEN: {
            return {
                ...state,
                token: null
            };
        }
        case READCOUNT: {
            const { read } = action.payload!;
            return {
                ...state,
                read
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default notificationReducer;

export const NOTIFICATION_API_REDUCER_KEY = "notificationApi";

export const notificationApi = createApi({
  reducerPath: NOTIFICATION_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getNotificationList: builder.query<NotificationList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        return {
          url: ApiConstant.ALL_NOTIFICATIONS,
          method: "POST",
          body: args,
        };
      },
      providesTags: ["Notifications"],
    }),
    downloadErrorFile: builder.query<DownloadErrorFile, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.DOWNLOAD_ERROR_FILE}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetNotificationListQuery,
  useLazyGetNotificationListQuery,
  useDownloadErrorFileQuery
} = notificationApi;
