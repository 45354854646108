// third-party
import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from "react-redux";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
// import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// import { persistReducer } from "redux-persist";
// project import
import reducers from "./reducers";
import { employerApi } from "./reducers/employer";
import { moduleApi } from "./reducers/modules";
import { rolesApi } from "./reducers/roles";
import { adminApi } from "./reducers/admin-user";
import { employeeApi } from "./reducers/employee";
import { schemeTypeApi } from "./reducers/schemeType";
import { providerApi } from "./reducers/provider";
import { notificationApi } from "./reducers/notification";
import { investmentOptionApi } from "./reducers/investmentOptions";
import { productMasterApi } from "./reducers/product-master";
import { pensionFundApi } from "./reducers/pensionFunds";
import { supplierApi } from "./reducers/suppliers";
import { avcPlanApi } from "./reducers/avcPlans";
import { pensionTypeApi } from "./reducers/pensionType";
// import { pensionFundApi } from "./reducers/pensionFunds"
// import { pensionTypeApi } from './reducers/pensionType'
import { payPeriodAPI } from "./reducers/payPeriod";
import { schemeApi } from "./reducers/scheme";
import { masterDataChangelogApi } from "./reducers/changelog";
import { imsWebminarApi } from "./reducers/ims-webinar";
import { cmsApi } from "./reducers/cms-pages";
import { journeyApi } from "./reducers/journey";
import { responsesApi } from "./reducers/responses";
import { consentApi } from "./reducers/consent";
import { smartEngineProductAPI } from "./reducers/smartEngineProduct";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

// export const appStorage = createIdbStorage({ name: "eco-admin", storeName: "keyval" }) //createIdbStorage({ name: "eco-admin", storeName: "app" })

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage: appStorage,
//   serialize: true, // Data serialization is not required and disabling it allows you to inspect storage value in DevTools
//   stateReconciler: hardSet,
// };

// const persistedReducer = persistReducer<any, any>(persistConfig, reducers);

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      employerApi.middleware,
      moduleApi.middleware,
      rolesApi.middleware,
      adminApi.middleware,
      employeeApi.middleware,
      schemeTypeApi.middleware,
      providerApi.middleware,
      notificationApi.middleware,
      investmentOptionApi.middleware,
      productMasterApi.middleware,
      pensionFundApi.middleware,
      supplierApi.middleware,
      avcPlanApi.middleware,
      pensionTypeApi.middleware,
      payPeriodAPI.middleware,
      schemeApi.middleware,
      masterDataChangelogApi.middleware,
      imsWebminarApi.middleware,
      cmsApi.middleware,
      journeyApi.middleware,
      consentApi.middleware,
      smartEngineProductAPI.middleware,
      responsesApi.middleware
    ]),
});

export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

const persister = persistStore(store);

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, persister, useSelector, useDispatch };
