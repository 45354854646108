import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import { ProductMasterEditInfo, ProductMasterInfo, ProductMasterList, SearchParam } from "types/product-master";
import baseQuery from "./baseQuery";

export const PRODUCT_MASTER_API_REDUCER_KEY = "productMasterApi";

export const productMasterApi = createApi({
  reducerPath: PRODUCT_MASTER_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["ProductMaster"],
  endpoints: (builder) => ({
    getProductMasterList: builder.query<
      ProductMasterList,
      Partial<SearchParam>
    >({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "" } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.PRODUCT_MASTER_LISTING,
          method: "POST",
          body: { ...body, searchText },
        };
      },
      providesTags: ["ProductMaster"],
    }),
    getActiveProductMasterList: builder.query<ProductMasterList, string>({
      query: () => {
        return {
          url: ApiConstant.PRODUCT_ACTIVE_MASTER_LISTING,
          method: "GET",
        };
      },
      providesTags: ["ProductMaster"],
    }),
    getPrductMasterById: builder.query<ProductMasterInfo, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PRODUCT_MASTER_BY_ID(id)}`,
          method: "GET",
        };
      }
    }),
    editPrductMaster: builder.mutation<ProductMasterInfo, ProductMasterEditInfo>({
      query(body) {
        return {
          url: `${ApiConstant.EDIT_PRODUCT_MASTER_BY_ID}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["ProductMaster"],
    }),
  }),
});

export const { useGetProductMasterListQuery, useGetActiveProductMasterListQuery, useEditPrductMasterMutation,
  useGetPrductMasterByIdQuery, useLazyGetPrductMasterByIdQuery} = productMasterApi;
