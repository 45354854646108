import { createApi } from "@reduxjs/toolkit/query/react";
import { ConsentDetailResponse, ConsentData, SearchParam, UnsubscribeEmail } from "types/consent";
import { ApiConstant } from "constants/ApiConstants";
import baseQuery from "./baseQuery";

const HEALTH_CHECK_CONSENT_REDUCER_PATH = "healthCheckConsentApi";

export const consentApi = createApi({
  reducerPath: HEALTH_CHECK_CONSENT_REDUCER_PATH,
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getHealthConsentByID: builder.query<ConsentDetailResponse, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        return {
          url: `${ApiConstant.HEALTH_CONSENT_DETAIL}`,
          method: "POST",
          body: args
        };
      },
    }),
    updateHealthConsent: builder.mutation<ConsentDetailResponse, Partial<ConsentData>>({
      query(body: ConsentData) {
        return {
          url: ApiConstant.UPDATE_HEALTH_CONSENT,
          method: "PATCH",
          body,
        };
      },
    }),
    unsubscribeEmail: builder.mutation<ConsentDetailResponse, Partial<UnsubscribeEmail>>({
      query(body: UnsubscribeEmail) {
        return {
          url: ApiConstant.UNSUBSCRIBE_EMAIL,
          method: "POST",
          body,
        };
      },
    })
  }),
});

export const { useGetHealthConsentByIDQuery, useUpdateHealthConsentMutation, useUnsubscribeEmailMutation } = consentApi;
