import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AvcPlanList,
  SearchParam,
  AvcPlanByIdResponse,
  //CommentInfo,
  AvcPlanStatus,
  AvcPlanStatusResponse,
  DownloadCSVResponse,
} from "../../types/avcPlans";
import { ApiConstant } from "../../constants/ApiConstants";
import { current } from "@reduxjs/toolkit";
import baseQuery from "./baseQuery";
import { ActiveSchemeList } from "types/provider";
import {
  ActivePayPeriodList,
  ActiveProviderList,
} from "types/investmentOptions";

export const AVCPLAN_API_REDUCER_KEY = "avcPlansApi";

export const avcPlanApi = createApi({
  reducerPath: AVCPLAN_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["AvcPlans", "ActiveProvider"],
  endpoints: (builder) => ({
    getAvcPlanList: builder.query<AvcPlanList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.AVC_PLANS,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["AvcPlans"],
    }),
    // createSupplier: builder.mutation<
    //   CreateSupplierResponse,
    //   Partial<CreateSupplier>
    // >({
    //   query(body) {
    //     return {
    //       url: ApiConstant.CREATE_PENSION_FUND,
    //       method: "POST",
    //       body,
    //     };
    //   },
    //   transformErrorResponse: (response: {
    //     data: { error: string; message: string };
    //     status: number;
    //   }): string => response.data.error,
    //   invalidatesTags: ["AvcPlans"],
    // }),
    // updateSupplierOption: builder.mutation<
    //   CreateSupplierResponse,
    //   UpdateSupplier
    // >({
    //   query({ ID, ...body }) {
    //     return {
    //       url: ApiConstant.UPDATE_PENSION_FUND(ID),
    //       method: "PATCH",
    //       body,
    //     };
    //   },
    //   transformErrorResponse: (response: {
    //     data: { error: string; message: string };
    //     status: number;
    //   }): string => response.data.error,
    //   invalidatesTags: ["AvcPlans"],
    // }),
    getAvcPlanById: builder.query<AvcPlanByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.AVC_PLANS_BY_ID(id)}`,
          method: "GET",
        };
      },
    }),
    // addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
    //   query: (body) => {
    //     return {
    //       url: ApiConstant.ADD_COMMENT,
    //       method: "POST",
    //       body,
    //     };
    //   },
    //   transformErrorResponse: (response: {
    //     data: { error: string; message: string };
    //     status: number;
    //   }): string => response.data.error,
    // }),
    // getActiveInvestmentOptionList: builder.query<ActiveInvestmentOptionsList, {}>({
    //   query: () => {
    //     return {
    //       url: ApiConstant.GET_ALL_ACTIVE_INVESTMENT_OPTIONS,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["ActiveInvestmentOption"],
    // }),
    changeAvcPlanStatus: builder.mutation<AvcPlanStatusResponse, AvcPlanStatus>(
      {
        query: (body: AvcPlanStatus) => {
          const { ID, Enabled } = body;
          return {
            url: ApiConstant.CHANGE_PENSION_FUND_STATUS,
            method: "POST",
            body: { ID, Enabled },
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { ID, Enabled, ...rest } = args;
            const { data: updatedInfo } = await queryFulfilled;
            console.log("Data", updatedInfo);
            const patchedResult = dispatch(
              avcPlanApi.util.updateQueryData(
                "getAvcPlanList",
                rest,
                (draft) => {
                  console.log("UUData", draft.data);
                  const objIndex = draft.data.findIndex(
                    (item, index) => item.id === updatedInfo.data.ID
                  );
                  if (objIndex !== -1) {
                    const info = draft.data[objIndex];
                    info.Enabled = updatedInfo.data.Enabled;
                    draft.data[objIndex] = info;
                  }
                  console.log("Draft Info", current(draft.data));
                }
              )
            );
            console.log("Pathched", patchedResult);
          } catch (error) {
            console.log("Error", error);
          }
        },
      }
    ),
    getActiveSchemes: builder.query<ActiveSchemeList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_SCHEME,
          method: "GET",
        };
      },
    }),
    getActiveProvider: builder.query<ActiveProviderList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_PROVIDER,
          method: "GET",
        };
      },
      providesTags: ["ActiveProvider"],
    }),
    getPayrollIdentifiers: builder.query<ActivePayPeriodList, {}>({
      query: () => {
        return {
          url: ApiConstant.AVC_PLANS_PAYROLL_IDENTIFIER,
          method: "GET",
        };
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      },
    }),
    downloadCSVFromAnyUrl: builder.query<DownloadCSVResponse, string>({
      query: (url: string) => {
        return {
          url: `${url}`,
          method: "GET",
          headers: {
            "Referrer-Policy": "no-referrer",
          },
        };
      },
    }),
    // downloadCSV: builder.query<DownloadCSVResponse, string>({
    //   query: (type: string) => {
    //     return {
    //       url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
    //       method: "GET",
    //     };
    //   },
    // }),
  }),
});

export const {
  useGetAvcPlanListQuery,
  //useCreateSupplierMutation,
  //useUpdateSupplierOptionMutation,
  useGetAvcPlanByIdQuery,
  //useAddCommentMutation,
  useChangeAvcPlanStatusMutation,
  useLazyDownloadCSVQuery,
  useGetActiveSchemesQuery,
  useGetActiveProviderQuery,
  useGetPayrollIdentifiersQuery,
  useLazyDownloadCSVFromAnyUrlQuery,
} = avcPlanApi;
