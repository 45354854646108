import React, { createContext, useEffect } from 'react';

// reducer - state management
// import { LOGIN, LOGOUT } from 'store/reducers/actions';
// import authReducer from 'store/reducers/auth';
import { useAuth0 } from "@auth0/auth0-react";
import { setlogout, setLogin } from '../store/reducers/login';

// project import
import Loader from 'components/Loader';
import { KeyedObject } from 'types/root';
import { Auth0ContextType } from 'types/auth';
import { dispatch as authdispatch, useSelector } from '../store/index';
import { getAuthInfo } from 'selectors/auth'
import { enqueueSnackbar } from 'notistack';

// const initialState: AuthProps = {
//   isLoggedIn: false,
//   isInitialized: false,
//   user: null
// };

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

export const Auth0Provider = ({ children }: { children: React.ReactElement }) => {
  // const [state, dispatch] = useReducer(authReducer, initialState);
  const { state } = useSelector((state) => ({
    state: getAuthInfo(state),
  }));

  const {
    isLoading,
    user,
    isAuthenticated,
    loginWithRedirect,
    logout: authLogout,
    getAccessTokenSilently,
    error
  } = useAuth0();

  useEffect(() => {
    const init = async () => {
      if (error) {
        enqueueSnackbar(error?.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 2000,
          }
        );
        authLogout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
        authdispatch(setlogout())
      }else{
        try {
          const isLoggedIn = isAuthenticated
          if (isLoggedIn) {
            const token = await getAccessTokenSilently()
            const newState = {
              isLoggedIn: true,
              isInitialized: true,
              token,
              user: {
                id: user?.sub,
                avatar: user?.picture,
                email: user?.email,
                name: user?.name,
                tier: 'Premium'
              }
            }
            authdispatch(setLogin(newState))
            // dispatch({
            //   type: LOGIN,
            //   payload: {
            //     isLoggedIn: true,
            //     user: {
            //       id: user?.sub,
            //       email: user?.email
            //     }
            //   }
            // });
          } else {
            authdispatch(setlogout())
            // dispatch({
            //   type: LOGOUT
            // });
          }
        } catch (err) {
          authdispatch(setlogout())
          // dispatch({
          //   type: LOGOUT
          // });
        }
      }
    };

    init();
  }, [authLogout, error, getAccessTokenSilently, isAuthenticated, user]);

  const login = async (options?: KeyedObject) => {
    await loginWithRedirect();
    const isLoggedIn = isAuthenticated

    if (isLoggedIn) {
      console.log(user)
      const token = await getAccessTokenSilently()
      const newState = {
        isLoggedIn: true,
        isInitialized: true,
        token,
        user: {
          id: user?.sub,
          avatar: user?.picture,
          email: user?.email,
          name: user?.name,
          tier: 'Premium'
        }
      }
      authdispatch(setLogin(newState))
      // dispatch({
      //   type: LOGIN,
      //   payload: {
      //     isLoggedIn: true,
      //     user: {
      //       id: user?.sub,
      //       avatar: user?.picture,
      //       email: user?.email,
      //       name: user?.name,
      //       tier: 'Premium'
      //     }
      //   }
      // });
    }
  };

  const logout = () => {
    authLogout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
    authdispatch(setlogout())
    // dispatch({
    //   type: LOGOUT
    // });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (isLoading) {
    return <Loader />;
  }

  return <Auth0Context.Provider value={{ ...state, isLoading, login, logout, resetPassword, updateProfile }}>{children}</Auth0Context.Provider>;
};

export default Auth0Context;
