import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputLabel, FormControl, FormHelperText, Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormFieldProps } from ".";

export interface DateControlProps extends FormFieldProps {
  value: Date | null;
  onChange: (value: Date | null) => void;
  maxDate?: Date;
  minDate?: Date;
}

const DateControl = (props: DateControlProps) => {
  const {
    label,
    required,
    value,
    onChange,
    error,
    helperText,
    disabled,
    maxDate,
    minDate,
  } = props;
  return (
    <Stack spacing={1}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Stack spacing={1}>
        <FormControl sx={{ width: "100%" }} error={error}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              maxDate={maxDate}
              disabled={disabled}
              format="dd-MMM-yyyy"
              value={value}
              onChange={(value) => onChange(value)}
              minDate={minDate}
              slotProps={{ textField: { placeholder: "DD-MM-YYYY" } }}
            />
          </LocalizationProvider>
        </FormControl>
      </Stack>
      {error && <FormHelperText error={true}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default DateControl;
