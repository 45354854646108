import { useEffect } from "react";

const useEscapeKey = (handleClose: any) => {
  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };
    document.addEventListener("keydown", handleEsc);

    return () => document.removeEventListener("keydown", handleEsc);
  }, [handleClose]);
};

export default useEscapeKey;
