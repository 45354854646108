import { createApi } from "@reduxjs/toolkit/query/react";
import { ProviderList, SearchParam, CreateProvider, CreateProviderResponse, UpdateProviderResponse, UpdateProvider, ProviderByIdResponse, CommentInfo, AddComment, ActiveSchemeList, ProviderStatusResponse, ProviderStatus, DownloadCSVResponse } from "../../types/provider";
import { ApiConstant } from "../../constants/ApiConstants";
import { current } from "@reduxjs/toolkit";
import baseQuery from "./baseQuery";

export const PROVIDERS_API_REDUCER_KEY = "providersApi";

export const providerApi = createApi({
  reducerPath: PROVIDERS_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Providers"],
  endpoints: (builder) => ({
    getProvidersList: builder.query<ProviderList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.PROVIDERS,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["Providers"],
    }),
    createProvider: builder.mutation<CreateProviderResponse, Partial<CreateProvider>>({
      query(body) {
        return {
          url: ApiConstant.CREATE_PROVIDER,
          method: "POST",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Providers"],
    }),
    updateProvider: builder.mutation<UpdateProviderResponse, UpdateProvider>({
      query({ id, ...body }) {
        return {
          url: ApiConstant.UPDATE_PROVIDER(id),
          method: "PATCH",
          body,
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Providers"]
    }),
    getProviderById: builder.query<ProviderByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.PROVIDER_BY_ID(id)}`,
          method: "GET",
        };
      }
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.ADD_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),
    getActiveSchemes: builder.query<ActiveSchemeList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_SCHEME,
          method: "GET",
        };
      },
    }),
    changeProviderStatus: builder.mutation<ProviderStatusResponse, ProviderStatus>({
      query: (body: ProviderStatus) => {
        const { ID, Enabled } = body;
        return {
          url: ApiConstant.CHANGE_PROVIDER_STATUS,
          method: "POST",
          body: { ID, Enabled }
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, Enabled, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            providerApi.util.updateQueryData(
              "getProvidersList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.Enabled = updatedInfo.data.Enabled;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      }
    }),
  }),
});

export const { useGetProvidersListQuery, useCreateProviderMutation, useUpdateProviderMutation, useGetProviderByIdQuery, useAddCommentMutation, useGetActiveSchemesQuery, useChangeProviderStatusMutation, useLazyDownloadCSVQuery } = providerApi;
