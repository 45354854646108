// // types
// import { PaletteThemeProps } from 'types/theme';
// import { PalettesProps } from '@ant-design/colors';
// import { PaletteColorOptions } from '@mui/material/styles';

// // ==============================|| PRESET THEME - DEFAULT ||============================== //

// const Default = (colors: PalettesProps): PaletteThemeProps => {
//   const { blue, red, gold, cyan, green, grey } = colors;
//   const greyColors: PaletteColorOptions = {
//     0: grey[0],
//     50: grey[1],
//     100: grey[2],
//     200: grey[3],
//     300: grey[4],
//     400: grey[5],
//     500: grey[6],
//     600: grey[7],
//     700: grey[8],
//     800: grey[9],
//     900: grey[10],
//     A50: grey[15],
//     A100: grey[11],
//     A200: grey[12],
//     A400: grey[13],
//     A700: grey[14],
//     A800: grey[16]
//   };
//   const contrastText = '#fff';

//   return {
//     primary: {
//       lighter: blue[0],
//       100: blue[1],
//       200: blue[2],
//       light: blue[3],
//       400: blue[4],
//       main: blue[5],
//       dark: blue[6],
//       700: blue[7],
//       darker: blue[8],
//       900: blue[9],
//       contrastText
//     },
//     secondary: {
//       lighter: greyColors[100],
//       100: greyColors[100],
//       200: greyColors[200],
//       light: greyColors[300],
//       400: greyColors[400],
//       main: greyColors[500]!,
//       600: greyColors[600],
//       dark: greyColors[700],
//       800: greyColors[800],
//       darker: greyColors[900],
//       A100: greyColors[0],
//       A200: greyColors.A400,
//       A300: greyColors.A700,
//       contrastText: greyColors[0]
//     },
//     error: {
//       lighter: red[0],
//       light: red[2],
//       main: red[4],
//       dark: red[7],
//       darker: red[9],
//       contrastText
//     },
//     warning: {
//       lighter: gold[0],
//       light: gold[3],
//       main: gold[5],
//       dark: gold[7],
//       darker: gold[9],
//       contrastText: greyColors[100]
//     },
//     info: {
//       lighter: cyan[0],
//       light: cyan[3],
//       main: cyan[5],
//       dark: cyan[7],
//       darker: cyan[9],
//       contrastText
//     },
//     success: {
//       lighter: green[0],
//       light: green[3],
//       main: green[5],
//       dark: green[7],
//       darker: green[9],
//       contrastText
//     },
//     grey: greyColors
//   };
// };

// export default Default;

// types

import { PaletteThemeProps } from "types/theme";

import { PalettesProps } from "@ant-design/colors";

import { PaletteColorOptions } from "@mui/material/styles";

import { ThemeMode } from "types/config";

// ==============================|| PRESET THEME - PURPLE DEFAULT ||============================== //

const Default = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  const { grey } = colors;

  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16],
  };

  const contrastText = "#fff";

  let primaryColors = [
    // "#e6f2fb",
    // "#CAEDF9",
    // "#97D8F4",
    // "#5FB1DF",
    // "#3687C0",
    // "#075497",
    // "#054181",
    // "#03306C",
    // "#022257",
    // "#011848",
    "#EDEAF2",
    "#DBD4E6",
    "#C9BFD9",
    "#A594C0",
    "#6F549A",
    "#391474",
    "#2E105D",
    "#220C46",
    "#17082E",
    "#0B0417",
  ];

  let errorColors = ["#FDE8E7", "#F25E52", "#F04134", "#EE3B2F", "#E92A21"];
  let warningColors = ["#FFF7E0", "#FFC926", "#FFBF00", "#FFB900", "#FFA900"];
  let infoColors = ["#E0F4F5", "#26B0BA", "#00A2AE", "#009AA7", "#008694"];
  let successColors = ["#ebf7d7", "#ACE371", "#52A51B", "#2B760D", "#114F05"];

  if (mode === ThemeMode.DARK) {
    primaryColors = [
      "#e6f2fb",
      "#CAEDF9",
      "#97D8F4",
      "#5FB1DF",
      "#3687C0",
      "#075497",
      "#054181",
      "#03306C",
      "#022257",
      "#011848",
    ];

    errorColors = ["#321d1d", "#7d2e28", "#d13c31", "#e66859", "#f8baaf"];
    warningColors = ["#342c1a", "#836611", "#dda705", "#e9bf28", "#f8e577"];
    infoColors = ["#1a2628", "#11595f", "#058e98", "#1ea6aa", "#64cfcb"];
    successColors = ["#ebf7d7", "#ACE371", "#52A51B", "#2B760D", "#114F05"];
  }

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText,
    },

    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500]!,
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0],
    },

    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText,
    },

    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100],
    },

    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText,
    },

    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText,
    },
    grey: greyColors,
  };
};

export default Default;
