import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  ResponsesDetailsResponse,
  ResponsesList,
  SearchParam,
} from "types/responses";

export const RESPONSES_API_REDUCER_KEY = "responsesApi";

export const responsesApi = createApi({
  reducerPath: RESPONSES_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Responses"],
  endpoints: (builder) => ({
    getResponsesList: builder.query<ResponsesList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        return {
          url: ApiConstant.GET_ALL_RESPONSE_LIST,
          method: "POST",
          body: args,
        };
      },
      providesTags: ["Responses"],
    }),
    getResponsesById: builder.query<ResponsesDetailsResponse, string>({
      query: (ID: string) => {
        return {
          url: `${ApiConstant.GET_RESPONSE_DETAILS}`,
          method: "POST",
          body: { ID },
        };
      },
    }),
  }),
});

export const { useGetResponsesListQuery, useGetResponsesByIdQuery } =
  responsesApi;
