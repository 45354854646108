import { createApi } from "@reduxjs/toolkit/query/react";
import {
  SupplierList,
  SearchParam,
  CreateSupplierResponse,
  CreateSupplier,
  UpdateSupplier,
  SupplierByIdResponse,
  CommentInfo,
  AddComment,
  SupplierStatus,
  SupplierStatusResponse,
  DownloadCSVResponse,
} from "../../types/suppliers";
import { ApiConstant } from "../../constants/ApiConstants";
import { current } from "@reduxjs/toolkit";
import baseQuery from "./baseQuery";

export const SUPPLIER_API_REDUCER_KEY = "suppliersApi";

export const supplierApi = createApi({
  reducerPath: SUPPLIER_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Suppliers"],
  endpoints: (builder) => ({
    getSupplierList: builder.query<SupplierList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const {
          pageNo = 1,
          size = 30,
          searchText = "",
          sortColumn = "Name",
          sortOrder = "ASC",
          filter,
        } = args;
        const body = { pageNo, size, searchText, sortColumn, sortOrder };
        return {
          url: ApiConstant.GET_ALL_SUPPLIERS,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["Suppliers"],
    }),
    createSupplier: builder.mutation<
      CreateSupplierResponse,
      Partial<CreateSupplier>
    >({
      query(body) {
        return {
          url: ApiConstant.CREATE_SUPPLIER,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Suppliers"],
    }),
    updateSupplierOption: builder.mutation<
      CreateSupplierResponse,
      UpdateSupplier
    >({
      query({ ID, ...body }) {
        return {
          url: ApiConstant.UPDATE_SUPPLIER(ID),
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Suppliers"],
    }),
    getSupplierById: builder.query<SupplierByIdResponse, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.SUPPLIER_BY_ID(id)}`,
          method: "GET",
        };
      },
    }),
    addComment: builder.mutation<CommentInfo, Partial<AddComment>>({
      query: (body) => {
        return {
          url: ApiConstant.ADD_SUPPLIER_COMMENT,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
    }),

    changeSupplierStatus: builder.mutation<
      SupplierStatusResponse,
      SupplierStatus
    >({
      query: (body: SupplierStatus) => {
        const { ID, Enabled } = body;
        return {
          url: ApiConstant.CHANGE_SUPPLIER_STATUS,
          method: "POST",
          body: { ID, Enabled },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, Enabled, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            supplierApi.util.updateQueryData(
              "getSupplierList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.Enabled = updatedInfo.data.Enabled;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    downloadCSV: builder.query<DownloadCSVResponse, string>({
      query: (type: string) => {
        return {
          url: ApiConstant.DOWNLOAD_MASTER_ENTITY_FILE(type),
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetSupplierListQuery,
  useCreateSupplierMutation,
  useUpdateSupplierOptionMutation,
  useGetSupplierByIdQuery,
  useAddCommentMutation,
  useChangeSupplierStatusMutation,
  useLazyDownloadCSVQuery,
} = supplierApi;
