import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ReactTable from "components/ReactTable";
import { Column } from "react-table";

type CustomDialogProps = DialogProps & {
    title: string;
    closeButton: ReactNode;
    loading?: boolean;
    onCancel: () => void;
    errorDialog?: Boolean;
    data: { id: string, ProductName: string, sequence: number }[];
    onUpdate: (data: any[]) => void,
    isFetching: boolean,
    dragFun: (data: any[]) => void,
    sequenceUpdateColumns: Column[]
};

const CustomDraggableReactTable: React.FC<CustomDialogProps> = (props: CustomDialogProps) => {
    const { title, closeButton, onCancel, errorDialog, loading = false, data, isFetching, dragFun, onUpdate, sequenceUpdateColumns, ...restProps } =
        props;

    return (
        <Dialog {...restProps} maxWidth="xs" fullWidth>
            <DialogContent sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <DialogTitle sx={{ textAlign: "start" }}>{title}</DialogTitle>

                {closeButton}
            </DialogContent>

            <DialogContent sx={{
                // display: "flex",
                // flexDirection: "row",
                // justifyContent: "space-between",
                // alignItems: "center"
            }}>
                <ReactTable
                    dragable={true}
                    dragDisable={(dt) => { return false }}
                    onDrag={dragFun}
                    columns={sequenceUpdateColumns}
                    data={data}
                    isFetching={isFetching}
                />
            </DialogContent>

            {(
                <DialogActions sx={{ p: "20px" }}>
                    <Button
                        onClick={onCancel}
                        color="primary"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1 }}
                    >
                        {`Cancel`}
                    </Button>
                    <LoadingButton fullWidth
                        disabled={loading}
                        variant="contained"
                        type="button"
                        loading={loading}
                        onClick={() => onUpdate(data)}
                    >
                        Update
                    </LoadingButton>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CustomDraggableReactTable;
