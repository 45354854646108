import React from 'react';
import CountrySelectComponent, { CountrySelectProps } from 'components/@extended/CountrySelect';
import DateControlComponent, { DateControlProps } from './DateControl';
import InputComponent, { InputControlProps } from './Input';
import RadioControl, { RadioControlProps as RadioProps } from './RadioControl';
import SwitchControl, { SwitchControlProps as SwitchProps } from './SwitchControl';
import { InputBaseProps } from '@mui/material';
import SelectControl, { SelectControlProps as SelectProps } from './SelectControl';
import CheckControl, { CheckControlProps } from './CheckControl';
import MutiSelectControl, { MutiSelectControlProps as MutiControlProps } from './MutiSelectControl';
import AsyncSelect, {SelectControlProps as selectAsyncProps} from './AsyncSelect';

export interface FormFieldProps {
  label?: string;
  required?: boolean;
  id: string;
  name: string;
  onBlur?: InputBaseProps['onBlur'];
  error?: boolean;
  helperText?: string | React.ReactNode;
  disabled?: boolean;
}

type TextControlProps = InputControlProps & { control?: 'text'; };

type DateProps = DateControlProps & { control: 'date' };

type CountryControlProps = CountrySelectProps & {
  control: 'country';
};

type RadioControlProps = RadioProps & {
  control: 'radio';
};

type SwitchControlProps = SwitchProps & {
  control: 'switch';
};

type SelectControlProps = SelectProps & {
  control: 'select';
};

type CheckboxControlProps = CheckControlProps & {
  control: 'checkbox';
};

type MultiSelectControlProps = MutiControlProps & {
  control: 'multiple';
};

type AsyncSelectControlProps = selectAsyncProps & {
  control: 'select-async';
};

export type ControlProps = TextControlProps | DateProps | CountryControlProps | RadioControlProps
  | SwitchControlProps | SelectControlProps | CheckboxControlProps | MultiSelectControlProps |
  AsyncSelectControlProps;

const FormField: React.FC<ControlProps> = (props) => {
  const { control = "text", ...rest } = props;
  switch (control) {
    case 'text':
      return <InputComponent {...rest as InputControlProps} />;
    case 'date':
      return <DateControlComponent {...rest as DateControlProps} />;
    case 'country':
      return <CountrySelectComponent {...rest as CountryControlProps} />;
    case 'radio':
      return <RadioControl {...rest as RadioControlProps} />;
    case 'switch':
      return <SwitchControl {...rest as SwitchControlProps} />;
    case 'select':
      return <SelectControl {...rest as SelectControlProps} />;
    case 'checkbox':
      return <CheckControl {...rest as CheckControlProps} />;
    case 'multiple':
      return <MutiSelectControl {...rest as MutiControlProps} />;
    case 'select-async':
      return <AsyncSelect {...rest as selectAsyncProps} />;
    default:
      return <InputComponent {...rest as InputControlProps} />;
  }
};

export default FormField;
