import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
    SearchParam,
    PaginatedSmartEngineProduct,
    SmartEngineProductResponse,
    ThemeResponse,
    CreateSmartProductSequencePayLoad,
    UpdateSmartProductSequencePayLoad
} from "types/smart-engine-product";
import { ProductMasterList } from "types/product-master";

export const SCHEME_API_REDUCER_KEY = "smartEngineProductAPI";

export const smartEngineProductAPI = createApi({
    reducerPath: SCHEME_API_REDUCER_KEY,
    baseQuery: baseQuery,
    tagTypes: ["SmartEngineProduct"],
    endpoints: (builder) => ({
        getSmartEngineProductList: builder.query<PaginatedSmartEngineProduct, Partial<SearchParam>>({
            query: (args: SearchParam) => {
                return {
                    url: ApiConstant.GET_ALL_SMART_ENGINE_PRODUCTS,
                    method: "POST",
                    body: args,
                };
            },
            providesTags: ["SmartEngineProduct"],
        }),
        getEngineProductById: builder.query<SmartEngineProductResponse, string>({
            query: (id: string) => {
                return {
                    url: `${ApiConstant.GET_BY_ID_PRODUCT_SEQUENCE}`,
                    method: "POST",
                    body: {
                        "ID": id
                    }
                };
            },
        }),
        getAllActiveThemes: builder.query<ThemeResponse, any>({
            query: () => {
                return {
                    url: `${ApiConstant.GET_ALL_ACTIVE_THEMES}`,
                    method: "GET",
                };
            },
        }),
        getAllActiveProducts: builder.query<ProductMasterList, any>({
            query: () => {
                return {
                    url: `${ApiConstant.PRODUCT_ACTIVE_MASTER_LISTING}`,
                    method: "GET",
                };
            },
        }),
        createProductSequence: builder.mutation<any, CreateSmartProductSequencePayLoad>({
            query: (body: CreateSmartProductSequencePayLoad) => {
                return {
                    url: `${ApiConstant.CREATE_PRODUCT_SEQUENCE}`,
                    method: "POST",
                    body: body
                };
            },
        }),
        updateProductSequence: builder.mutation<any, UpdateSmartProductSequencePayLoad>({
            query: (body: UpdateSmartProductSequencePayLoad) => {
                return {
                    url: `${ApiConstant.UPDATE_PRODUCT_SEQUENCE}`,
                    method: "POST",
                    body: body
                };
            },
        }),
        deleteProductSequence: builder.mutation<any, { ID: string }>({
            query: (body: { ID: string }) => {
                return {
                    url: `${ApiConstant.DELETE_PRODUCT_SEQUENCE}`,
                    method: "DELETE",
                    body: body
                };
            },
        })
    }),
});

export const {
    useGetSmartEngineProductListQuery,
    useGetEngineProductByIdQuery,
    useGetAllActiveThemesQuery,
    useGetAllActiveProductsQuery,
    useCreateProductSequenceMutation,
    useUpdateProductSequenceMutation,
    useDeleteProductSequenceMutation
} = smartEngineProductAPI;
