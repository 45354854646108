import { createApi } from "@reduxjs/toolkit/query/react";
import { current } from "@reduxjs/toolkit";
import {
  CreateRole,
  CreateRoleData,
  RoleInfoDetails,
  RoleList,
  SearchParam,
  UpdateRoleData,
  DeleteRoleResponse,
  DeleteRoleQuery,
  GetAllRoleInfo,
  UpdateRoleStatus,
  GetRoleInfo,
  ActiveRoleList,
} from "../../types/roles";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {moduleApi} from './modules'

export const EMPLOYER_API_REDUCER_KEY = "rolesApi";

export const rolesApi = createApi({
  reducerPath: EMPLOYER_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Roles"],
  endpoints: (builder) => ({
    getRoleList: builder.query<RoleList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "" } = args;
        return {
          url: ApiConstant.ROLE_LISTING,
          method: "POST",
          body: { pageNo, size, searchText },
        };
      },
      providesTags: ["Roles"],
    }),

    getActiveRoles: builder.query<ActiveRoleList, string>({
      query: () => {
        return {
          url: `${ApiConstant.GET_ACTIVE_ROLES}`,
          method: "GET",
        };
      },
    }),

    getRoleById: builder.query<GetRoleInfo, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.ROLE_BY_ID}/${id}`,
          method: "GET",
        };
      },
    }),
    addRole: builder.mutation<CreateRoleData, Partial<CreateRole>>({
      query(body) {
        return {
          url: ApiConstant.CREATE_ROLE,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Roles"],
    }),
    editRole: builder.mutation<
      UpdateRoleData,
      Partial<RoleInfoDetails> & Pick<GetAllRoleInfo, "ID">
    >({
      query(body) {
        return {
          url: `${ApiConstant.CREATE_ROLE}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Roles"],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(moduleApi.util.invalidateTags(["ModuleAccess"]))
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    changeRoleStatus: builder.mutation<UpdateRoleStatus, string>({
      query: (body) => {
        return {
          url: ApiConstant.CHANGE_ROLE_STATUS,
          method: "POST",
          body,
        };
      },
      // invalidatesTags: ['Employers'],
      // eslint-disable-next-line no-empty-pattern
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            rolesApi.util.updateQueryData(
              "getRoleList",
              { pageNo: 1, size: 30, searchText: "" },
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === updatedInfo.data.ID
                );
                if (objIndex !== -1) {
                  const info = draft.data[objIndex];
                  info.IsActive = updatedInfo.data.IsActive;
                  draft.data[objIndex] = info;
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    deleteRole: builder.mutation<DeleteRoleResponse, DeleteRoleQuery>({
      query: (args: DeleteRoleQuery) => {
        const { ID } = args;
        return {
          url: ApiConstant.DELETE_ROLE(ID),
          method: "DELETE",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            rolesApi.util.updateQueryData("getRoleList", rest, (draft) => {
              console.log("UUData", draft.data);
              const objIndex = draft.data.findIndex(
                (item, index) => item.ID === ID
              );
              if (objIndex !== -1) {
                draft.data.splice(objIndex, 1);
              }
              console.log("Draft Info", current(draft.data));
            })
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
  }),
});

export const {
  useGetRoleListQuery,
  useGetActiveRolesQuery,
  useGetRoleByIdQuery,
  useAddRoleMutation,
  useEditRoleMutation,
  useChangeRoleStatusMutation,
  useDeleteRoleMutation,
} = rolesApi;
